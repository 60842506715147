<template>
  <div class="box-page" style="padding-right: 15px">
    <div class="filter">
      <a-form layout="horizontal" ref="formRef" :model="formState">
        <a-form-item label="质检总开关">
          <a-switch v-model:checked="isChecked" @change="handelChecked"/>
        </a-form-item>
        <a-form-item label="线路质检配置" v-if="isAdminUser === 'system'">
          <a-row :gutter="24">
            <a-col :span="6">
              <a-form-item label="用户名" name="uid">
                <a-select
                    v-model:value="formState.uid"
                    style="width: 100%"
                    placeholder="请选择用户名"
                    show-search allow-clear>
                  <a-select-option
                      :value="item.value"
                      :label="item.label"
                      v-for="item in userOptions"
                      :key="item.value"
                  >{{ item.label }}
                  </a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="线路名称" name="amountId">
                <a-select
                    v-model:value="formState.amountId"
                    style="width: 100%"
                    placeholder="请选择线路名称"
                    show-search allow-clear>
                  <a-select-option
                      :value="item.value"
                      :label="item.label"
                      v-for="item in amountOption"
                      :key="item.value"
                  >{{ item.label }}
                  </a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="10">
              <a-form-item>
                <a-button type="primary" @click="handelSearch">
                  查询
                </a-button>
                <a-button :style="{ marginLeft: '8px' }" @click="handelReset">
                  重置
                </a-button>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form-item>
      </a-form>
    </div>
    <div class="table-list">
      <el-table
          v-loading="loading"
          ref="multipleTable"
          :data="tableData"
          size="mini"
          :row-style="{height:'50px'}"
          :cell-style="{padding:'0px'}"
          tooltip-effect="dark"
          style="width: 100%; overflow: auto"
          :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }"
      >
        <el-table-column v-if="isAdminUser === 'system'" label="用户名" prop="nickName"></el-table-column>
        <el-table-column label="线路名称" prop="amountName"></el-table-column>
        <el-table-column label="质检比例" prop="chance">
          <template #default="{row}">
            <a-input style="width: 100px" suffix="%" v-model:value="row.chance" @blur="handelUpadte(row)"></a-input>
          </template>
        </el-table-column>
        <el-table-column label="开关" prop="status">
          <template #header>
            <div class="question">
              <span>开关</span>
              <el-tooltip
                  effect="light"
                  placement="bottom"
              >
                <template #content>
                  <div class="questionTip">开关控制单个是否质检</div>
                </template>
                <div class="questionIcon" style="display: inline-block;margin-left: 10px">?</div>
              </el-tooltip>
            </div>
          </template>
          <template #default="{row}">
            <a-switch :checked="row.status"
                      :checked-value="0" :un-checked-value="1"
                      @change="(val) => handelUpadte(row, val)"/>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pager" style="padding-top: 8px">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageInfo.currentPage"
          :page-size="pageInfo.pageSize"
          background :page-sizes="[10, 20, 30, 40]" layout="total, sizes, prev, pager, next, jumper"
          :total="pageInfo.total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {reactive, toRaw} from 'vue';
import _lineService from "@/api/open/LineQualityInspections";
import {PageModule} from '../common/pagination';

/*
  质检总开关 
  用户名 uid 用户id 企业名称 amountId 服务商id 
*/
class FromModule {
  constructor() {
    this.uid = undefined
    this.amountId = undefined
    this.fieldA = ''
  }
}

/*
  用户名 nickName
  线路名称 amountName 质检比例 chance
  开关 status
  "uid": "100008",
  "chance": 0,  # 质检概率
  "amountId": "9", # 服务商id
  "createTime": "2022-07-26 17:49:22",
  "updateTime": null, 
  "id": "5",
  "amountName": "电信回拨", #服务商名称 
  "status": 1,       #状态: 0:正常 1:禁用
  "company": "duofang",  # 企业名
  "nickName": json,      # 用户名
*/
class TableModule {
  constructor(item) {
    this.uid = item.uid
    this.chance = item.chance
    this.amountId = item.amountId
    this.createTime = item.createTime
    this.updateTime = item.updateTime
    this.id = item.id
    this.amountName = item.amountName
    this.status = item.status
    this.company = item.company
    this.nickName = item.nickName
  }
}

/**
 * "optionType": "voiceCheck",
 "uid": "0",
 "optionKey": "interfaceEnable",
 "createTime": "2022-07-21 15:28:50",
 "optionValue": "1",   # 开关状态(0:关 1:开)
 "typeName": "语音质检",
 "updateTime": null,
 "remark": "线路开关(0:关 1:开))",
 "id": "6",
 "optionName": "线路开关"
 */
class OpenModule {
  constructor(item) {
    this.optionType = item?.optionType
    this.uid = item?.uid
    this.optionKey = item?.optionKey
    this.createTime = item?.createTime
    this.optionValue = item?.optionValue
    this.typeName = item?.typeName
    this.updateTime = item?.updateTime
    this.remark = item?.remark
    this.id = item?.id
    this.optionName = item?.optionName
  }

  getUpdateObj(val) {
    return {
      optionType: 'voiceCheck',
      optionKey: 'interfaceEnable',
      id: this.id,
      uid: this.uid,
      optionName: this.optionName,
      typeName: this.typeName,
      remark: this.remark,
      optionValue: val ? '1' : '0'
    }
  }

  getIsChecked() {
    return this.optionValue === '1'
  }
}

export default {
  props: {
    amountOption: {
      type: Array,
      default: () => []
    },
    userOptions: {
      type: Array,
      default: () => []
    },
    isAdminUser: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      tableData: [],
      loading: false,
      formState: reactive(new FromModule()),
      pageInfo: new PageModule(),
      isChecked: false,
      openModule: new OpenModule(),
    };
  },
  methods: {
    handleSizeChange(val) {
      this.pageInfo.setPageSize(val)
      if (this.isAdminUser === 'system') {
        this.getTableList()
      } else {
        this.getUserOptions()
      }
    },
    handleCurrentChange(val) {
      this.pageInfo.setCurrentPage(val)
      if (this.isAdminUser === 'system') {
        this.getTableList()
      } else {
        this.getUserOptions()
      }
    },
    getParams() {
      const obj = toRaw(this.formState)
      return {
        ...this.pageInfo.getParamsJson(),
        uid: obj.uid,
        amountId: obj.amountId
      }
    },
    async handelChecked(val) {
      await _lineService._manageService.updateEdit(this.openModule.getUpdateObj(val))
      if (this.isAdminUser === 'system') {
        this.getTableList()
      } else {
        this.getUserOptions()
      }
      this.$message.success('操作成功')
    },
    handelSearch() {
      this.pageInfo.init()
      if (this.isAdminUser === 'system') {
        this.getTableList()
      } else {
        this.getUserOptions()
      }
    },
    async getTableList() {
      this.loading = true;
      try {
        const res = await _lineService._manageService.getUserBigOptions(this.getParams())
        this.tableData = res.records.map(item => new TableModule(item))
        this.pageInfo.setTotal(res.total)
      } catch (err) {
        this.$message.error(err.message)
      }
      this.loading = false;
    },
    async getOpenData() {
      const res = await _lineService._manageService.getAllValue({
        optionType: 'voiceCheck',
        optionKey: 'interfaceEnable'
      })

      this.openModule = new OpenModule(res?.data)
      this.isChecked = this.openModule.getIsChecked()
    },
    async updateOpen() {
      const res = await _lineService._manageService.updateEdit()
      console.log(res)
    },
    async getUserOptions() {
      const res = await _lineService._recordService.getUserOptions()
      this.tableData = res.data.map(item => new TableModule(item))
    },
    handelReset() {
      this.$refs.formRef.resetFields()
      this.handelSearch()
    },
    async handelUpadte(row, val) {
      // 0:已开启；1：未开启（反人类）
      console.log('handelUpadte', row.status, val)
      let res = await _lineService._manageService.updateData({
        "uid": row.uid,
        "amountId": row.amountId,
        "status": typeof val !== 'undefined' ? val : row.status,
        "chance": row.chance
      })
      if (res?.code == 200) {
        row.status = val;
      }
      // if (this.isAdminUser === 'system') {
      //   this.getTableList()
      // } else {
      //   this.getUserOptions()
      // }
    },
    initData() {
      if (this.isAdminUser) {
        if (this.isAdminUser === 'system') {
          this.getTableList()
        } else {
          this.getUserOptions()
        }

        this.getOpenData()
      }
    },
  },
  created() {
    this.initData();
  },
  watch: {
    isAdminUser(newV, preV) {
      // console.log('manage isAdminUser', newV, preV);
      if (newV && newV != preV) {
        this.initData();
      }
    }
  },
};
</script>
<style lang="less" scoped>
.box-page /deep/ .ant-form-item .ant-form-item {
  margin-bottom: 0;
}
</style>