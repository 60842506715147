<template>
  <a-modal ref="processDialog" width="480px" :visible="show" title="处理办法">
    <div>上传图片（png/jpg）</div>
    <a-upload
        :action="imageUpdate.uploadUrl"
        :headers="imageUpdate.header"
        :name="imageUpdate.name"
        v-model:file-list="imageUpdate.fileList"
        list-type="picture-card"
        :before-upload="beforeImageUpload"
        @change="onImageChange"
        @preview="onImagePreview"
    >
      <div v-if="imageUpdate.fileList.length < 9">
        <plus-outlined/>
        <div class="ant-upload-text">上传图片</div>
      </div>
    </a-upload>
    <div>上传文件（pdf/doc）</div>
    <a-upload-dragger
        :action="fileUpdate.uploadUrl"
        :headers="fileUpdate.header"
        :name="fileUpdate.name"
        v-model:file-list="fileUpdate.fileList"
        :multiple="true"
        :before-upload="beforeFileUpload"
        @change="onFileChange"
    >
      <p class="ant-upload-drag-icon">
        <inbox-outlined></inbox-outlined>
      </p>
      <p class="ant-upload-text">点击或拖拽文件上传</p>
      <p class="ant-upload-hint">
        支持单次或批量上传。
      </p>
    </a-upload-dragger>

    <a-form layout="vertical" label-align="right" :model="rowJson" :rules="processRules" ref="processDialogFormRef"
            style="margin-top: 15px">
      <a-form-item label="处理办法" name="processingMethod">
        <a-textarea
            v-model:value="rowJson.processingMethod"
            placeholder="请输入处理办法"
            :auto-size="{ minRows: 5, maxRows: 8 }"/>
        <!--          <a-input style="min-height: 100px"-->
        <!--              v-model:value="rowJson.processingMethod"-->
        <!--                   placeholder="请输入处理办法"/>-->
      </a-form-item>
    </a-form>
    <template #footer>
      <a-button key="back" @click="oCancel">取消</a-button>
      <a-button key="submit" type="primary" :loading="buttonLoading" @click="oConfirm">保存
      </a-button>
    </template>
  </a-modal>

  <a-modal :visible="previewVisible" :footer="null" @cancel="onPreviewDialogCancel">
    <img style="width: 100%" :src="previewUrl"/>
  </a-modal>
</template>

<script>
import Cookies from "js-cookie";
import _lineService from "@/api/open/LineQualityInspections";
import {ElMessage} from "element-plus";
import img from "@/api/common/img";

export default {
  name: "process-dialog",
  emits: ['update:show'],
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
    rowJson: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  data() {
    return {
      imageUpdate: {
        name: 'file',
        // uploadUrl: '/api/system/upload/file', // 请求地址,
        // uploadUrl: 'api/system/upload/important-file', // 永久文件上传,
        uploadUrl: 'api/web/upload/important-file', // 图片上传,
        // uploadUrl: '/system/upload/file', // 临时文件上传,
        header: {Authorization:Cookies.get('Authorization')}, // 请求头部
        fileList: [], //文件列表
      },
      fileUpdate: {
        name: 'file',
        // uploadUrl: '/api/system/upload/file', // 请求地址,
        // uploadUrl: 'api/system/upload/important-file', // 永久文件上传,
        uploadUrl: 'api/web/upload/important-file', // 图片上传,
        // uploadUrl: '/system/upload/file', // 临时文件上传,
        header: {Authorization:Cookies.get('Authorization')}, // 请求头部
        fileList: [], //文件列表
      },
      previewVisible: false,
      previewUrl: '',
      buttonLoading: false,
      processRules: {
        processingMethod: [
          {required: true, message: '请输入处理办法', trigger: 'blur'}
        ],
      },
    }
  },
  watch: {
    show(newV, preV) {
      //监听a-modal显示状态，更新show
      if (!newV) {
        // console.log('watch show update', newV, preV)
        this.$emit('update:show', false);
      }
    },
    rowJson(newV, preV) {
      if (newV) {
        if (preV && preV.id == newV.id) {
          return;
        }
        // this.imageUpdate.fileList = [];
        if (newV.processingImages) {
          this.imageUpdate.fileList = Array.isArray(newV.processingImages) ? newV.processingImages?.map((item, index) => {
            return {
              uid: index + '',
              name: item.substring(item.lastIndexOf("/") + 1),
              status: 'done',
              url: item,
            }
            // return {url: item}
          }) : newV.processingImages.split(',')?.map((item, index) => {
            return {
              uid: index + '',
              name: item.substring(item.lastIndexOf("/") + 1),
              status: 'done',
              url: item,
            }
          });
        } else {
          this.imageUpdate.fileList = [];
        }

        if (newV.processingFiles) {
          this.fileUpdate.fileList = Array.isArray(newV.processingFiles) ? newV.processingFiles?.map((item, index) => {
            return {
              uid: index + '',
              name: item.substring(item.lastIndexOf("/") + 1),
              status: 'done',
              url: item,
            }
          }) : newV.processingFiles.split(',')?.map((item, index) => {
            return {
              uid: index + '',
              name: item.substring(item.lastIndexOf("/") + 1),
              status: 'done',
              url: item,
            };
          });
        } else {
          this.fileUpdate.fileList = [];
        }

        console.log('watch rowJson', newV.processingImages, newV.processingFiles, this.imageUpdate.fileList, this.fileUpdate.fileList);
        // this.imageUpdate.fileList = [
        //   {
        //     'url': "http://hiszy.oss-cn-beijing.aliyuncs.com/upload/prd/admins/66/883a7e0f955bed2ad860821bcd8f5976.png"
        //   }
        // ];
      }
    },

  },
  methods: {
    isImage(type) {
      //js 文件类型参考：https://cloud.tencent.com/developer/article/1538457
      //判断是图片类型，项目只需要png,jpg
      return type == 'image/png' || type == 'image/jpeg'
    },
    isFile(type) {
      //js 文件类型参考：https://cloud.tencent.com/developer/article/1538457
      //判断是文件类型，项目只需要doc,pdf
      return type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'//docx
          || type == 'application/msword'//doc
          || type == 'application/pdf'//pdf
          || type == 'application/vnd.ms-excel'//xls
    },
    async previewFile(file) {
      console.log('Your upload file:', file);
      // Your process logic. Here we just mock to the same file
      // const res = await fetch('https://next.json-generator.com/api/json/get/4ytyBoLK8', {
      //   method: 'POST',
      //   body: file,
      // });
      // const {thumbnail} = await res.json();
      // console.log('previewFile', thumbnail)
      let url = file?.url || file?.response?.data?.url;
      // if (file && url) {
      //   this.previewUrl = url;
      //   this.previewVisible = true;
      // }
      return url;
    },
    onImagePreview(file, fileList) {
      let url = file?.url || file?.response?.data?.url;
      // console.log('onImagePreview', file, url)
      if (file && url) {
        this.previewUrl = url;
        this.previewVisible = true;
      }
    },
    beforeImageUpload(file, fileList) {
      // console.log('beforeImageUpload', file, fileList);
      let canUpload = this.isImage(file?.type);
      if (!canUpload) {
        ElMessage.error('只支持图片类型：png/jpg')
      }
      //由于路由代理问题，选择手动上传，见confirm方法
      return false;
    },
    beforeFileUpload(file, fileList) {
      // console.log('beforeFileUpload', file, fileList);
      let canUpload = this.isFile(file?.type);
      if (!canUpload) {
        ElMessage.error('只支持文档类型：pdf/doc')
      }
      //由于路由代理问题，选择手动上传，见confirm方法
      return false;
    },
    onImageChange({file, fileList, event}) {
      // console.log('onImageChange', file, fileList, this.imageUpdate.fileList);
    },

    onFileChange({file, fileList, event}) {
      // console.log('onFileChange', file, fileList, this.imageUpdate.fileList);
    },

    onPreviewDialogCancel() {
      this.previewVisible = false;
    },
    oCancel() {
      this.$emit('update:show', false);
    },
    async oConfirm() {
      // console.log('oConfirm', this.imageUpdate?.fileList);
      this.buttonLoading = true;

      for (let i = 0; i < this.imageUpdate?.fileList?.length || 0; i++) {
        let url = this.imageUpdate?.fileList[i].url || this.imageUpdate?.fileList[i].response?.data?.url;
        let originFileObj = this.imageUpdate?.fileList[i].originFileObj;
        if (!url && originFileObj && (originFileObj instanceof File)) {
          const formData = new FormData()
          formData.append('file', originFileObj)
          let res = await img.imgWebUpload(formData);
          // console.log('oConfirm res', res);
          if (res?.data?.url) {
            this.imageUpdate.fileList[i].url = res?.data?.url;
            this.imageUpdate.fileList[i].response = res;
          }
        }
      }
      for (let i = 0; i < this.fileUpdate?.fileList?.length || 0; i++) {
        let url = this.fileUpdate?.fileList[i].url || this.fileUpdate?.fileList[i].response?.data?.url;
        let originFileObj = this.fileUpdate?.fileList[i].originFileObj;
        if (!url && originFileObj && (originFileObj instanceof File)) {
          const formData = new FormData()
          formData.append('file', originFileObj)
          let res = await img.imgWebUpload(formData);
          // console.log('oConfirm res', res);
          if (res?.data?.url) {
            this.fileUpdate.fileList[i].url = res?.data?.url;
            this.fileUpdate.fileList[i].response = res;
          }
        }
      }

      // return;
      let processingImages = this.imageUpdate?.fileList?.filter(file => {
        let url = file?.url || file?.response?.data?.url;
        return url;
      })?.map(file => file?.url || file?.response?.data?.url)?.join(',') || '';
      let processingFiles = this.fileUpdate?.fileList?.filter(file => {
        let url = file?.url || file?.response?.data?.url;
        return url;
      })?.map(file => file?.url || file?.response?.data?.url)?.join(',') || '';

      await this.$refs.processDialogFormRef.validate()

      let id = this.rowJson.id;
      let processingMethod = this.rowJson.processingMethod;
      await _lineService._recordService.updateVoiceProcessingMethod({
        id: id,
        processingMethod: processingMethod,
        processingImages: processingImages,
        processingFiles: processingFiles,
      }).then(res => {
        // console.log('processDialogConfirm res', res)
        this.buttonLoading = false;
        if (res?.code == 200) {
          ElMessage.success('审核成功')
          // this.$message.success('审核成功')
          this.$emit('update:show', false);
          // let changeObj = {processingMethod: processingMethod};
          let changeObj = {processingMethod: processingMethod, processingImages, processingFiles};
          this.$emit('confirm', changeObj);
          // this.updateTableData(id, {processingMethod: processingMethod});
          this.$refs.processDialogFormRef.resetFields()
        }
      }).catch(e => {
        console.log('processDialogConfirm e', e)
        ElMessage.error((e?.data?.message) || '审核失败')
        // this.$message.error((e?.data?.message) || '审核失败')
        this.buttonLoading = false;
        // this.$message.error(e)
      })
    },
  },
}
</script>

<style scoped>

</style>