<template>
  <!--  报备运营商  -->
  <div class="m3-width-80 m3-flex-col m3-justify-content-start">
    <div v-if="record.orderFrom == 1">当前为
      <a-button class="m3-margin-horizontal-xs">运营商质检</a-button>
      ，无需报备
    </div>
    <div v-else-if="!needReportDesc">当前使用线路为
      <a-button class="m3-margin-horizontal-xs">{{ amountName }}</a-button>
      ，无需报备
    </div>
    <div v-else-if="needReportDesc">当前使用线路为
      <a-button class="m3-margin-horizontal-xs">{{ amountName }}</a-button>
      ，需报备
    </div>
    <a-form v-if="needReportDesc"
            ref="editFormRef" layout="horizontal"
            :model="editForm"
            :label-col="labelCol" :wrapper-col="wrapperCol"
            class="m3-width-full m3-margin-top-xl">
      <a-form-item label="报备模板" name="reportDesc">
        <div class="m3-relative">
          <a-textarea :disabled="formDisabled" v-model:value="editForm.reportDesc" :rows="8">
          </a-textarea>
          <a-button class="m3-absolute" :style="{bottom:'16px',right:'16px'}" size="small" type="link"
                    @click="copyText">
            复制
          </a-button>
        </div>
      </a-form-item>
      <a-form-item label="是否报备">
        <a-checkbox :disabled="formDisabled" v-model:checked="checked">我已报备</a-checkbox>
      </a-form-item>
    </a-form>

    <div class="m3-absolute m3-flex m3-justify-content-end m3-padding-s"
         :style="{bottom:'20px',right:'20px'}">
      <a-button type="primary" :loading="buttonLoading" @click="pre">上一步</a-button>
      <a-button class="m3-margin-left-s" type="primary" :loading="buttonLoading" @click="next">下一步</a-button>
      <a-button class="m3-margin-left-s" @click="cancel">取消</a-button>
    </div>
  </div>
</template>

<script>
import AButton from "ant-design-vue/lib/button/button";
import {computed, reactive, ref, toRefs, watch} from "vue";
import EmptyUtil from "@/utils/common/EmptyUtil";
import {Form, message} from "ant-design-vue";
import {safePromise} from "@/utils/common/safePromise";
import _lineService from "@/api/open/LineQualityInspections";
import {modalConfirm} from "@/utils/common/modalConfirm";
import ObjectUtil from "@/utils/common/ObjectUtil";
import {copy} from "@/utils/common";
import {useStore} from "vuex";

export default {
  name: "StepReport",
  emits: ['next'],
  components: {AButton},
  props: {
    record: {
      type: Object,
      default: {},
    },
  },
  setup(props, context) {
    let {record} = toRefs(props);
    let store = useStore();
    let isAdmin = computed(() => store.getters?.userType == 'system');

    let needReportDesc = computed(() => {
      let res = true;
      if (record.value.orderFrom == 1) {
        //运营商质检无需报备
        res = false;
      } else if (record.value.amountId == 9 || record.value.amountId == 23) {
        //amountId为9，23（电信AXB，电信回拨）无需报备
        res = false;
      }
      return res;
    })
    let amountName = computed(() => record.value?.amountName || '');

    let formDisabled = computed(() => !EmptyUtil.isEmpty(record.value?.id) && record.value?.status >= 6);
    let buttonLoading = ref(false);
    let editFormRef = ref();
    let checked = ref(false);
    let editForm = reactive({
      id: null,//id
      reportDesc: null,//报备模板
      // amountId: null,//运营商id，（不需要报备：9,23;）
      // orderFrom: null,//违规来源
      // checkId: null,//质检记录ID
      // infractions: null,//违规次数
      // violDesc: null,//违规说明
      // orderFrom: null,//违规来源
      // oprPenalties: null,//运营商处罚内容
      // punishPropType: null,//建议处罚类型 0-提醒 1-保证金（金额） 2-罚款（金额） 3-关停主叫 4-提醒/保金证（金额） 5-禁用主叫/罚款（金额）
      // punishPropVal: null,//建议处罚量 punish_prop_type为0，此值为null； punish_prop_type为1或2，此值为金额，单位元；punish_prop_type为3，此值为时长，单位小时；
    });

    let editFormRules = {
      reportDesc: [{required: true, message: '请输入报备模板', trigger: 'blur',}],
    }
    let resetForm = () => {
      editForm.id = null;
      editForm.reportDesc = null;
    }
    //useForm 表单验证
    let useForm = Form.useForm;
    const {resetFields, validate, validateInfos} = useForm(editForm, editFormRules);

    let getReportTemplate = async () => {
      let res = await safePromise(_lineService._workOrderService.getReportTemplate({id: record.value?.id}));
      editForm.reportDesc = res?.data?.template;
    }
    watch(record, (newV, preV) => {
      if (!EmptyUtil.isEmpty(newV)) {
        resetForm();
        Object.assign(editForm, newV);
        console.log('StepReport watch record', editForm)
        if (EmptyUtil.isEmpty(editForm.reportDesc) && EmptyUtil.isEmpty(newV?.reportDesc) && !EmptyUtil.isEmpty(newV?.id) && newV?.status < 6) {
          getReportTemplate();
        }
        if (!EmptyUtil.isEmpty(newV?.id) && newV?.status >= 6) {
          // 已提交过，则勾选展示
          checked.value = true;
        }
      } else {
        resetForm();
      }
    }, {immediate: true})


    let copyText = () => {
      if (!EmptyUtil.isEmpty(editForm.reportDesc)) {
        copy(editForm.reportDesc)
      } else {
        message.warning('请输入报备内容');
      }
    }
    let cancel = () => {
      context.emit('next', -1);
    }

    let pre = async () => {
      context.emit('next', 0);
    }
    let next = async () => {
      let confirm = true;
      let nextStatus = 6;
      if (!formDisabled.value) {
        if (!isAdmin.value) {
          await modalConfirm('抱歉，当前用户没有操作权限！');
          return;
        }
        confirm = await modalConfirm('确认提交？');
        if (confirm) {
          if (needReportDesc.value && EmptyUtil.isEmpty(editForm.reportDesc)) {
            await modalConfirm('请输入报备模板');
            return;
          }
          if (needReportDesc.value && !checked.value) {
            await modalConfirm('请勾选“我已报备”');
            return;
          }
          buttonLoading.value = true;
          let params = ObjectUtil.toParams(editForm);
          let res = await safePromise(_lineService._workOrderService.report(params));
          if (res?.code == 200) {
            record.value.reportDesc = editForm.reportDesc;
            context.emit('next', nextStatus);//传递下一个status进行跳转
            // resetForm();
          } else {
            message.error(res?.message || res?.msg || '提交失败');
          }
          buttonLoading.value = false;
        }
      } else {
        context.emit('next', nextStatus);//传递下一个status进行跳转
      }
    }

    return {
      labelCol: {span: 3},
      wrapperCol: {span: 14},
      needReportDesc, amountName, checked,
      formDisabled, editForm, editFormRules, editFormRef,
      buttonLoading, pre, next, cancel, copyText,
    }
  },
}
</script>

<style scoped>

</style>