<template>
  <!--  工单完结  -->
  <div class="m3-width-80 m3-flex-col m3-justify-content-start">
    <a-form ref="editFormRef" layout="horizontal"
            :model="editForm" :rules="editFormRules"
            :label-col="labelCol" :wrapper-col="wrapperCol"
            class="m3-width-full m3-flex-1 m3-relative">
      <div v-if="record.approval == 0">
        <!--        主管审核不通过   -->
        <a-form-item label="是否通过" name="approval">
          <a-radio-group disabled v-model:value="editForm.approval" :options="approvalList"
                         button-style="solid"/>
        </a-form-item>
        <a-form-item label="审批意见" name="disapprovalReason">
          <div class="m3-relative">
            <a-textarea disabled v-model:value="editForm.disapprovalReason"
                        :rows="4">
            </a-textarea>
            <a-button class="m3-absolute" :style="{bottom:'16px',right:'16px'}" size="small" type="link"
                      @click="copyText">复制
            </a-button>
          </div>
        </a-form-item>
      </div>
      <div v-else>
        <!--        主管审核通过   -->
        <a-form-item v-if="record.checkId" label="违规话单">
          <a-button @click="showLineRecordDialog">查看</a-button>
        </a-form-item>
        <a-form-item label="处罚结果">
          <div class="m3-flex">
            <a-select disabled v-model:value="punishTypeDesc" :options="punishTypeList"
                      class="m3-flex-2"></a-select>
            <span v-if="punishValueDesc" class="m3-margin-horizontal-s">-</span>
            <a-select v-if="punishValueDesc"
                      disabled v-model:value="punishValueDesc"
                      :options="punishValList"
                      class="m3-flex-5"></a-select>
          </div>
        </a-form-item>
        <a-form-item label="违规说明">
          <div class="m3-relative">
            <a-textarea disabled v-model:value="violDesc">
            </a-textarea>
            <a-button class="m3-absolute" :style="{bottom:'16px',right:'16px'}" size="small" type="link"
                      @click="copyViolDesc">复制
            </a-button>
          </div>
        </a-form-item>
        <a-form-item label="申诉说明" name="appealContent">
          <div class="m3-relative">
            <a-textarea disabled v-model:value="appealContentDesc">
            </a-textarea>
            <a-button class="m3-absolute" :style="{bottom:'16px',right:'16px'}" size="small" type="link"
                      @click="copyText">复制
            </a-button>
          </div>
        </a-form-item>
        <a-form-item v-if="imageUpdate.fileList&&imageUpdate.fileList.length>0"
                     label="整改方案" name="appealAttachment">
          <a-upload
              disabled
              :action="imageUpdate.uploadUrl"
              :headers="imageUpdate.header"
              :name="imageUpdate.name"
              v-model:file-list="imageUpdate.fileList"
              list-type="picture-card"
              @preview="onImagePreview"
              :before-upload="beforeImageUpload"
          >
          </a-upload>
        </a-form-item>

        <a-form-item label="处罚结果" name="punishFinalType">
          <div class="m3-flex">
            <a-select :disabled="formDisabled"
                      v-model:value="editForm.punishFinalType" :options="punishTypeList"
                      class="m3-flex-2"
                      @change="punishTypeChange"></a-select>
            <span class="m3-margin-horizontal-s">-</span>
            <a-select
                :disabled="formDisabled"
                v-model:value="editForm.punishFinalVal"
                :options="punishValList"
                class="m3-flex-5"></a-select>
          </div>
        </a-form-item>
        <a-form-item label="执行处罚" name="execType">
          <a-radio-group :disabled="formDisabled" v-model:value="editForm.execType" :options="execTypeList"
                         button-style="solid"/>
        </a-form-item>
      </div>
    </a-form>

    <div class="m3-absolute m3-flex m3-justify-content-end m3-padding-s"
         :style="{bottom:'20px',right:'20px'}">
      <a-button type="primary" :loading="buttonLoading" @click="pre">上一步</a-button>
      <a-button class="m3-margin-left-s" type="primary"
                :disabled="formDisabled" :loading="buttonLoading" @click="next">下一步
      </a-button>
      <a-button class="m3-margin-left-s" @click="cancel">取消</a-button>
    </div>
  </div>
  <a-modal :visible="imagePreviewDialogVisible" :footer="null" @cancel="imagePreviewDialogVisible = false">
    <img alt="previewImage" style="width: 100%" :src="previewImage"/>
  </a-modal>
</template>

<script>
import AButton from "ant-design-vue/lib/button/button";
import {computed, onUnmounted, reactive, ref, toRefs, watch} from "vue";
import EmptyUtil from "@/utils/common/EmptyUtil";
import Cookies from "js-cookie";
import {ElMessage} from "element-plus";
import {Form, message} from "ant-design-vue";
import {copy} from "@/utils/common";
import {safePromise} from "@/utils/common/safePromise";
import {modalConfirm} from "@/utils/common/modalConfirm";
import ObjectUtil from "@/utils/common/ObjectUtil";
import _lineService from "@/api/open/LineQualityInspections";
import {
  MAX_FORBIDDEN_HOUR,
  orderFromDesc, PUNISH_AMOUNT_LIST, PUNISH_DURATION_LIST,
  PUNISH_TYPE_LIST,
  punishTypeDesc,
  punishValueDesc
} from "@/views/open/workOrder/WorkOrder";
import {useStore} from "vuex";

export default {
  name: "StepComplete",
  emits: ['next', 'showLineRecordDialog'],
  components: {AButton},
  props: {
    record: {
      type: Object,
      default: {},
    },
  },
  setup(props, context) {
    let {record} = toRefs(props);
    let store = useStore();
    let isAdmin = computed(() => store.getters?.userType == 'system');

    let formDisabled = computed(() => !EmptyUtil.isEmpty(record.value?.id) && record.value?.status > 15);
    let buttonLoading = ref(false);
    let editFormRef = ref();
    let editForm = reactive({
      id: null,//质检记录ID
      execType: 0,//执行处罚方式，0-手动 1-系统自动
      punishFinalType: null,//最终处罚类型，管理员最终修改
      punishFinalVal: null,//最终处罚量，管理员最终修改
    });

    let punishTypeChange = () => {
      editForm.punishFinalVal = null;
      if (editForm.punishFinalType == 0) {
        delete editFormRules.punishFinalVal;
      } else {
        editFormRules.punishFinalVal = [{required: true, type: 'number', message: '请选择处罚量', trigger: 'change',}];
      }
    }

    let imagePreviewDialogVisible = ref(false);
    let previewImage = ref('');
    let imageUpdate = reactive({
      name: 'file',
      // uploadUrl: '/api/system/upload/file', // 请求地址,
      // uploadUrl: 'api/system/upload/important-file', // 永久文件上传,
      uploadUrl: 'api/web/upload/important-file', // 图片上传,
      // uploadUrl: '/system/upload/file', // 临时文件上传,
      header: {Authorization: Cookies.get('Authorization')}, // 请求头部
      fileList: [], //文件列表
    });
    let beforeImageUpload = (file, fileList) => {
      // console.log('beforeImageUpload', file, fileList);
      let canUpload = file?.type == 'image/png' || file?.type == 'image/jpeg';
      if (!canUpload) {
        ElMessage.error('只支持图片类型：png/jpg')
      }
      //由于路由代理问题，选择手动上传，见confirm方法
      return false;
    };
    let getBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }
    let onImagePreview = async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      previewImage.value = file.url || file.preview;
      imagePreviewDialogVisible.value = true;
      console.log('onImagePreview', file, previewImage.value);
    };

    let editFormRules = {
      execType: [{required: true, type: 'number', message: '请选择执行方式', trigger: 'blur',}],
      punishFinalType: [{required: true, type: 'number', message: '请选择处罚类型', trigger: 'change',}],
    }
    let resetForm = () => {
      editForm.execType = 0;
      editForm.punishFinalType = null;
      editForm.punishFinalVal = null;
    }
    //useForm 表单验证
    let useForm = Form.useForm;
    const {resetFields, validate, validateInfos} = useForm(editForm, editFormRules);

    watch(record, (newV, preV) => {
      if (!EmptyUtil.isEmpty(newV)) {
        resetForm();
        Object.assign(editForm, newV);
        console.log('StepAppeal watch record', editForm)
        editForm.execType = !EmptyUtil.isEmpty(editForm.execType) ? editForm.execType : 0;
        if (EmptyUtil.isEmpty(editForm.punishFinalType) && !EmptyUtil.isEmpty(newV?.punishConfType)) {
          editForm.punishFinalType = newV?.punishConfType;
        }
        if (EmptyUtil.isEmpty(editForm.punishFinalVal) && !EmptyUtil.isEmpty(newV?.punishConfVal)) {
          editForm.punishFinalVal = newV?.punishConfVal;
        }
      } else {
        resetForm();
      }
      if (newV?.appealAttachment) {
        imageUpdate.fileList = Array.isArray(newV.appealAttachment) ? newV.appealAttachment?.map((item, index) => {
          return {
            uid: index + '',
            name: item.substring(item.lastIndexOf("/") + 1),
            status: 'done',
            url: item,
          }
          // return {url: item}
        }) : newV.appealAttachment.split(',')?.map((item, index) => {
          return {
            uid: index + '',
            name: item.substring(item.lastIndexOf("/") + 1),
            status: 'done',
            url: item,
          }
        });
      } else {
        imageUpdate.fileList = [];
      }
    }, {immediate: true})


    //是否通过 0-不通过 1-通过
    let approvalList = [
      {
        value: 0,
        label: '不通过',
      },
      {
        value: 1,
        label: '通过',
      },
    ]
    //执行处罚方式，0-手动 1-系统自动
    let execTypeList = [
      {
        value: 0,
        label: '手动执行',
      },
      {
        value: 1,
        label: '系统自动执行',
        disabled: true,
      },
    ]

    //处罚类型 0-提醒 1-保证金（金额） 2-罚款（金额） 3-关停主叫 4-提醒/保金证（金额） 5-禁用主叫/罚款（金额）
    let punishTypeList = PUNISH_TYPE_LIST

    //处罚量 punish_prop_type为0，此值为null； punish_prop_type为1或2，此值为金额，单位元；punish_prop_type为3，此值为时长，单位小时；
    let punishValList = computed(() => {
      let res = [];
      if (editForm?.punishFinalType == 0) {
        res = []
      } else if (editForm?.punishFinalType == 1 || editForm?.punishFinalType == 2 || editForm?.punishFinalType == 4 || editForm?.punishFinalType == 5) {
        res = PUNISH_AMOUNT_LIST
      } else if (editForm?.punishFinalType == 3) {
        res = PUNISH_DURATION_LIST
      }
      return res;
    })

    let copyViolDesc = () => {
      if (!EmptyUtil.isEmpty(record.value?.violDesc)) {
        copy(record.value?.violDesc)
      } else {
        message.warning('暂无内容');
      }
    }
    let copyText = () => {
      if (!EmptyUtil.isEmpty(editForm.appealContent)) {
        copy(editForm.appealContent)
      } else {
        message.warning('请输入申诉说明');
      }
    }
    let showLineRecordDialog = () => {
      context.emit('showLineRecordDialog');
    }
    let cancel = () => {
      context.emit('next', -1);
    }

    let pre = () => {
      if (record.value.approval == 0) {
        context.emit('next', 6);
      } else {
        context.emit('next', 12);
      }
    }
    let next = async () => {
      let confirm = true;
      let nextStatus = 18;
      if (!formDisabled.value) {
        if (!isAdmin.value) {
          await modalConfirm('抱歉，当前用户没有操作权限！');
          return;
        }
        let valid = await safePromise(editFormRef.value?.validate());
        console.log('next valid', valid, EmptyUtil.isEmpty(valid?.errorFields));
        if (editForm.punishFinalType != 0 && EmptyUtil.isEmpty(editForm.punishFinalVal)) {
          message.error('请选择处罚量');
          return;
        }
        if (EmptyUtil.isEmpty(valid?.errorFields)) {
          //在编辑阶段，需要弹窗提示
          confirm = await modalConfirm('确认提交？', '提交后该工单将完结，完结后不可编辑修改');
        } else {
          confirm = false;
        }

        if (confirm) {
          buttonLoading.value = true;

          let params = ObjectUtil.toParams(editForm);
          let res = await safePromise(_lineService._workOrderService.complete(params));
          if (res?.code == 200) {
            record.value.status = nextStatus;
            record.value.execType = editForm.execType;//
            record.value.punishFinalType = editForm.punishFinalType;
            record.value.punishFinalVal = editForm.punishFinalVal;
            context.emit('next', nextStatus);//传递下一个status进行跳转
            // resetForm();
          } else {
            message.error(res?.message || res?.msg || '提交失败');
          }
          buttonLoading.value = false;
        }
      } else {
        context.emit('next', nextStatus);//传递下一个status进行跳转
      }
    }

    return {
      labelCol: {span: 3},
      wrapperCol: {span: 14},
      formDisabled, editForm, editFormRules, editFormRef,
      punishTypeChange, punishTypeList, punishValList, execTypeList, approvalList,
      imagePreviewDialogVisible, previewImage,
      imageUpdate, beforeImageUpload, onImagePreview,
      showLineRecordDialog,
      buttonLoading, pre, next, cancel, copyText, copyViolDesc,
    }
  },
  computed: {
    isOrderAppealed() {
      return this.record?.status >= 15
    },
    orderFromDesc() {
      //来源 0-平台质检 1-运营商质检
      return orderFromDesc(this.record?.orderFrom)
    },
    oprPenaltiesDesc() {
      return this.record?.oprPenalties
    },
    violDesc() {
      return this.record?.violDesc
    },
    appealContentDesc() {
      return this.record?.appealContent
    },
    punishTypeDesc() {
      return punishTypeDesc(this.record?.punishConfType)
    },
    punishValueDesc() {
      return punishValueDesc(this.record?.punishConfType, this.record?.punishConfVal)
    },
  },
}
</script>

<style scoped>

</style>