import { Request } from '@/http/request'

// vos用户设置--->列表 
export function vosSettingList(parameter: any) {
    return Request.axiosInstance.post('/open/voice-vos-user-industry/list', parameter)
}
// vos用户设置--->添加
export function vosSettingAdd(parameter: any) {
    return Request.axiosInstance.post('/open/voice-vos-user-industry/add', parameter)
}
// vos用户设置--->删除 
export function vosSettingDel(parameter: any) {
    return Request.axiosInstance.post('/open/voice-vos-user-industry/delete', parameter)
}
// vos用户设置--->修改 
export function vosSettingEdit(parameter: any) {
    return Request.axiosInstance.post('/open/voice-vos-user-industry/edit', parameter)
}
// vos用户设置--->id查询 
export function vosSettingSearch(parameter: any) {
    return Request.axiosInstance.post('/open/voice-vos-user-industry/get', parameter)
}
//质检白名单
export function whitePage(parameter: any) {
  return Request.axiosInstance.post('/open/voice-white/page', parameter)
}
//增加质检白名单
export function addBatch(parameter: any) {
  return Request.axiosInstance.post('/open/voice-white/add-batch', parameter)
}
//批量删除
export function deleteBatch(parameter: any) {
  return Request.axiosInstance.post('/open/voice-white/delete-batch', parameter)
}
export default { vosSettingList, vosSettingAdd, vosSettingDel, vosSettingEdit, vosSettingSearch,whitePage,addBatch,deleteBatch }