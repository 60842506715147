<template>
  <div>
    <!--    <div v-if="isAdminUser !== ''">-->
    <a-tabs default-active-key="1" @change="tabChange">
      <a-tab-pane key="1" tab="质检记录">
        <Record v-if="tabInit.record" :voiceOption="voiceOption" :amountOption="amountOption"
                :isAdminUser="isAdminUser"/>
      </a-tab-pane>
      <a-tab-pane key="2" tab="数据统计">
        <statistics v-if="tabInit.statistics" :isAdminUser="isAdminUser" :userOptions="userOptions"
                    :amountOption="amountOption"/>
      </a-tab-pane>
      <a-tab-pane key="3" tab="关键词配置">
        <RuleKey v-if="tabInit.ruleKey" :isAdminUser="isAdminUser" :userOptions="userOptions"
                 :voiceOption="voiceOption"/>
      </a-tab-pane>
      <a-tab-pane key="4" tab="规则配置" v-if="isAdminUser=='system'">
        <!--        <Rule v-if="tabInit.rule" :isAdminUser="isAdminUser" :voiceOption="voiceOption"/>-->
        <RuleTab v-if="tabInit.rule" :isAdminUser="isAdminUser" :voiceOption="voiceOption"/>
      </a-tab-pane>
      <a-tab-pane key="5" tab="配置管理">
        <manage v-if="tabInit.manage" :isAdminUser="isAdminUser" :userOptions="userOptions"
                :amountOption="amountOption"/>
      </a-tab-pane>
      <a-tab-pane key="6" tab="质检白名单" v-if="isAdminUser=='system'">
        <whiteList :isAdminUser="isAdminUser" :userOptions="userOptions"
                :amountOption="amountOption"/>
      </a-tab-pane>
    </a-tabs>
  </div>
  <!--  </div>-->

</template>
<script>
import Record from './LineQualityInspections/Record.vue'
import manage from './LineQualityInspections/manage.vue'
import RuleKey from './LineQualityInspections/RuleKey.vue'
import RuleTab from './LineQualityInspections/RuleTab.vue'
import whiteList from './LineQualityInspections/whiteList.vue'
// import Rule from './LineQualityInspections/Rule.vue'
import statistics from './LineQualityInspections/statistics.vue'
import _lineService from '@/api/open/LineQualityInspections'
import {Request} from "@/http/request";

export default {
  components: {
    Record,
    whiteList,
    manage,
    RuleKey,
    RuleTab,
    // Rule,
    statistics
  },
  data() {
    return {
      voiceOption: [],
      amountOption: [],
      userOptions: [],
      isAdminUser: '',
      //初始化，延迟加载
      tabInit: {
        record: true,
        statistics: false,
        ruleKey: false,
        rule: false,
        manage: false,
        whiteList: false
      }
    }
  },
  methods: {
    tabChange(key) {
      // console.log('tabChange', key);
      if (key == 1 && !this.tabInit.record) {
        this.tabInit.record = true
      }
      if (key == 2 && !this.tabInit.statistics) {
        this.tabInit.statistics = true
      }
      if (key == 3 && !this.tabInit.ruleKey) {
        this.tabInit.ruleKey = true
      }
      if (key == 4 && !this.tabInit.rule) {
        this.tabInit.rule = true
      }
      if (key == 5 && !this.tabInit.manage) {
        this.tabInit.manage = true
      }
      if (key == 6 && !this.tabInit.manage) {
        this.tabInit.whiteList = true
      }

    },
    async getAmountList() {
      const res = await _lineService._recordService.getAmountList()
      this.amountOption = res?.data?.map(item => {
        return {label: item.name, value: item.id}
      }) || [];
    },
    async getVoiceName() {
      const res = await _lineService._recordService.getVoiceName()
      this.voiceOption = res.data.map(item => {
        return {label: item.name, value: item.name}
      })
    },
    async getUserOptions() {
      const res = await _lineService._recordService.queryList()
      let systemOption = {label: '系统', value: 0};//添加一个系统项
      this.userOptions = res?.data?.map(item => {
        return {label: item.nickname, value: item.uid}
      }) || [];
      this.userOptions.splice(0, 0, systemOption);
    },
    async getUserType() {
      const res = await _lineService._recordService.getUserType()
      this.isAdminUser = res?.data;
    }
  },
  created() {
    Promise.all([this.getAmountList(), this.getVoiceName(), this.getUserOptions()]).then(() => {
    }).catch((err) => {
      this.$message.error(err.message)
    })
    this.getUserType()

  },
};
</script>