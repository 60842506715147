/**
 * 正整数
 * @param {*} min max
 */
export function pInteger (s) {
  return /^\+?[1-9][0-9]*$/.test(s)
}

/**
 * 限制范围
 * @param {int} min max
 */
export function range (s, min, max) {
  return !!s && s.length >= min && s.length <= max
}

/**
 * 邮箱
 * @param {*} s
 */
export function isEmail (s) {
  return /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/.test(s)
}

/**
 * 多个邮箱,';'号隔开
 * @param {*} s
 */
export function isMultiEmail (s) {
  /* eslint-disable-next-line */
  return /^((([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6}\;))*(([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})))$/.test(s)
}

/**
 * 手机号码
 * @param {*} s
 */
export function isMobile (s) {
  return /^1[3456789]\d{9}$/.test(s)
}

/**
 * 电话号码
 * @param {*} s
 */
export function isPhone (s) {
  return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(s)
}

/**
 * URL地址
 * @param {*} s
 */
export function isURL (s) {
  return /^http[s]?:\/\/.*/.test(s)
}

/**
 * 身份证
 * @param {*} s
 */
export function isId (s) {
  return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(s)
}

/**
 * 是否是图片类型
 * @param {*} type
 */
export function isImg (type) {
  if (type !== 'image/jpg' && type !== 'image/jpeg' && type !== 'image/png' && type !== 'image/gif') {
    this.$message.error('只支持jpg、png、gif格式的图片！')
    return false
  }
  return true
}

/**
 * 是否是视频类型['video/mp4', 'video/ogg', 'video/flv','video/avi','video/wmv','video/rmvb']
 * @param {*} type
 */
export function isVideo (type) {
  if (type !== 'video/mp4' && type !== 'video/ogg' && type !== 'video/flv' && type !== 'video/avi' && type !== 'video/wmv' && type !== 'video/rmvb') {
    this.$message.error('只支持mp4、ogg、flv、avi、wmv、rmvb格式的视频！')
    return false
  }
  return true
}

/**
 * 是否是压缩包类型
 * @param {*} type
 */
export function isZip (type) {
  if (type !== 'application/zip') {
    this.$message.error('只支持zip格式的图片！')
    return false
  }
  return true
}

/**
 * 是否是文档类型['application/msword'、'application/pdf']
 * @param {*} type
 */
export function isDoc (type) {
  if (type !== 'application/zip' && type !== 'application/pdf') {
    this.$message.error('只支持doc、docx、pdf格式的文档！')
    return false
  }
  return true
}

/**
 * 转换日期为yyyy-MM-dd HH:mm:ss字符串
 * @param date
 * @returns {string}
 */
export function formatTimeToStr (date) {
  let transferDate = new Date(date)
  let y = transferDate.getFullYear()
  let MM = transferDate.getMonth() + 1
  MM = MM < 10 ? ('0' + MM) : MM
  let d = transferDate.getDate()
  d = d < 10 ? ('0' + d) : d
  let h = transferDate.getHours()
  h = h < 10 ? ('0' + h) : h
  let m = transferDate.getMinutes()
  m = m < 10 ? ('0' + m) : m
  let s = transferDate.getSeconds()
  s = s < 10 ? ('0' + s) : s
  return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s
}