<template>
  <div class="box-page" style="padding-right: 15px">
    <div class="filter">
      <a-form layout="horizontal" ref="formRef" :model="formState">

        <a-row :gutter="24">
          <!--          <a-col :span="12">-->
          <!--            <a-form-item label="是否命中" name="risk">-->
          <!--              <a-radio-group v-model:value="formState.risk" button-style="solid">-->
          <!--                <a-radio-button value="4">全部</a-radio-button>-->
          <!--                <a-radio-button value="3">高</a-radio-button>-->
          <!--                <a-radio-button value="2">中</a-radio-button>-->
          <!--                <a-radio-button value="1">低</a-radio-button>-->
          <!--                <a-radio-button value="0">无</a-radio-button>-->
          <!--              </a-radio-group>-->
          <!--            </a-form-item>-->
          <!--          </a-col>-->
          <a-col :span="8">
            <a-form-item label="状&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;态" name="status">
              <a-radio-group v-model:value="formState.status">
                <a-radio value="1">禁用</a-radio>
                <a-radio value="0">正常</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="类型" name="type">
              <a-select
                  v-model:value="formState.type"
                  style="width: 100%"
                  placeholder="请选择类型"
                  show-search allow-clear>
                <a-select-option
                    :value="item.value" v-for="item in types" :key="item.value" :label="item.label">{{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="渠道名" name="uid" v-if="isAdminUser === 'system'">
              <a-select
                  v-model:value="formState.uid"
                  style="width: 100%"
                  placeholder="请选择渠道名"
                  show-search allow-clear>
                <a-select-option
                    :value="item.value"
                    :label="item.label"
                    v-for="item in userOptions"
                    :key="item.value">{{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-item label="关键词组" name="name">
              <a-select
                  v-model:value="formState.name"
                  style="width: 100%"
                  show-search allow-clear
                  placeholder="请输入关键词组名称">
                <a-select-option
                    :value="item.value"
                    :label="item.label"
                    v-for="item in voiceOption"
                    :key="item.value"
                >{{ item.label }}
                </a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item label="关键词" name="keywords">
              <a-input v-model:value="formState.keywords" placeholder="请输入敏感词"/>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item>
              <a-button type="primary" @click="handelSearch">
                查询
              </a-button>
              <a-button :style="{ marginLeft: '8px' }" @click="handelReset">
                重置
              </a-button>
              <a-button type="primary" :style="{ marginLeft: '8px' }" @click="addRuleKey">
                添加关键词组
              </a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-list" style="padding-top: 15px">
      <el-table
          v-loading="loading"
          ref="multipleTable"
          :data="tableData"
          size="mini"
          :row-style="{height:'50px'}"
          :cell-style="{padding:'0px'}"
          tooltip-effect="dark"
          style="width: 100%; overflow: auto"
          :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }"
      >
        <el-table-column label="类型" width="50" prop="type">
          <template #default="{row}">
            {{ typeDesc(row) }}
          </template>
        </el-table-column>
        <el-table-column v-if="isAdminUser === 'system'" label="渠道名称" width="100" prop="nickName"></el-table-column>
        <el-table-column label="企业名称" width="100" prop="terminalName"></el-table-column>
        <el-table-column label="行业" width="150" prop="industryName">
          <template #default="{row}">
            <el-tooltip placement="top">
              <template #content>
                <p class="ellipsis">
                  {{ row.industryNameList.join('/') }}
                </p>
              </template>
              <p class="ellipsis">
                <span v-for="(industryName,industryNameIndex) in row.industryNameList"
                      :key="industryName">
                  {{ industryName }}
                  <br v-if="industryNameIndex != row.industryNameList.length-1"/>
                </span>
              </p>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="关键词组" width="100">
          <template #default="{row}">
            <el-tooltip placement="top">
              <template #content>
                <p class="ellipsis">
                  {{ row.name }}
                </p>
              </template>
              <p class="ellipsis">
                {{ row.name }}
              </p>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="关键词" width="250">
          <template #default="{ row }">
            <el-tooltip v-if="row.keywords&&row.keywords.length>20" style="max-width: 250px">
              <template #content>
                <p class="ellipsis" style="max-width: 500px">
                  {{ row.keywords ? row.keywords : '--' }}
                </p>
              </template>
              <p class="ellipsis">
                {{ row.keywords ? row.keywords : '--' }}
              </p>
            </el-tooltip>
            <p v-else class="ellipsis">
              {{ row.keywords ? row.keywords : '--' }}
            </p>
          </template>
        </el-table-column>

        <!--        <el-table-column prop="riskText" min-width="150">-->
        <!--          <template #header>-->
        <!--            <div class="question">-->
        <!--              <span>级别</span>-->
        <!--            </div>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <el-table-column label="状态" prop="statusText" width="80">
          <template #header>
            <div class="question">
              <span>状态</span>
              <el-tooltip
                  effect="light"
                  placement="bottom">
                <template #content>
                  <div class="questionTip">开关控制单个是否质检</div>
                </template>
                <div class="questionIcon" style="display: inline-block;margin-left: 10px">?</div>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <!--        <el-table-column label="报备话术" width="450" prop="template"></el-table-column>-->
        <el-table-column label="报备话术" width="500">
          <template #default="{ row }">
            <el-tooltip v-if="row.template&&row.template.length>30">
              <template #content>
                <p class="ellipsis" style="max-width: 500px">
                  {{ row.template ? row.template : '--' }}
                </p>
              </template>
              <p class="ellipsis">
                {{ row.template ? row.template : '--' }}
              </p>
            </el-tooltip>
            <p v-else class="ellipsis">
              {{ row.template ? row.template : '--' }}
            </p>
          </template>
        </el-table-column>
        <el-table-column label="创建/更新" width="150px">
          <template #default="{row}">
            <p class="ellipsis">
              {{ row.createTime ? row.createTime : '--' }}
            </p>
            <p class="ellipsis">
              {{ row.updateTime ? row.updateTime : '--' }}
            </p>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template #default="{row}">
            <a-button size="small" style="margin-right: 8px" type="primary" @click="handelEdit(row)">编辑</a-button>
            <a-popconfirm
                title="确定删除此条数据?"
                ok-text="确定"
                cancel-text="取消"
                @confirm="handelConfirm(row)"
            >
              <a-button size="small" type="primary" danger>删除</a-button>
            </a-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pager" style="padding-top: 8px">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageInfo.currentPage"
          :page-size="pageInfo.pageSize"
          background :page-sizes="[10, 20, 30, 40]" layout="total, sizes, prev, pager, next, jumper"
          :total="pageInfo.total">
      </el-pagination>
    </div>

    <a-modal width="690px" v-model:visible="visible" title="添加关键词组" @cancel="handleCancel">
      <div class="add-from-s">
        <a-form layout="horizontal" label-align="right" :model="formCreateUpdate" :rules="rules" ref="formRef">
          <a-form-item has-feedback label="类型" name="type">
            <a-radio-group v-model:value="formCreateUpdate.type">
              <a-radio :value="1" :disabled="isScriptType">违规关键词</a-radio>
              <a-radio :value="0" :disabled="isScriptType">线路行业关键词</a-radio>
              <a-radio :value="2" disabled>报备话术关键词</a-radio>
            </a-radio-group>
          </a-form-item>

          <a-form-item v-show="isIndustryType" label="线路行业:">
            <el-cascader :props="industryTreeProps" filterable placeholder="选择/搜索线路行业" clearable
                         style="width: 100%"
                         v-model="industryValue"
                         :options="industryTreeList"
                         @change="industryChange"></el-cascader>
          </a-form-item>


          <span v-if="isScriptType"
                class="m3-flex m3-align-items-start"
                style="margin-bottom: 24px">
            <span>
              渠道名称：{{ formCreateUpdate.nickName || '--' }}
            </span>
            <span style="margin-left: 50px">
              企业名称：{{ formCreateUpdate.terminalName || '--' }}
            </span>
          </span>
          <a-row v-if="isScriptType" style="margin-bottom: 24px">
            <div class="m3-flex m3-align-items-start">
              <div class="m3-flex-1">报备话术：</div>
              <div class="m3-flex-8">{{ formCreateUpdate.template || '--' }}</div>

            </div>
            <!--            <p>报备话术：<span>{{ formCreateUpdate.template || '&#45;&#45;' }}</span></p>-->
          </a-row>

          <a-form-item v-if="isIndustryType" has-feedback label="关键词组名称" name="industryName">
            <a-input :disabled="isIndustryType"
                     v-model:value="formCreateUpdate.industryName" placeholder="请输入关键词组名称"></a-input>
          </a-form-item>
          <a-form-item v-if="isScriptType||isIrregularityType" has-feedback label="关键词组名称" name="name">
            <a-input :disabled="isIndustryType"
                     v-model:value="formCreateUpdate.name" placeholder="请输入关键词组名称"></a-input>
          </a-form-item>

          <a-form-item has-feedback label="状态" label-align="right" name="status">
            <a-radio-group v-model:value="formCreateUpdate.status">
              <a-radio :value="0">正常</a-radio>
              <a-radio :value="1">禁用</a-radio>
            </a-radio-group>
          </a-form-item>

          <a-form-item has-feedback label="关键词" name="keyword">
            <a-input v-model:value="formCreateUpdate.keyword" placeholder="请输入敏感词后回车"
                     @pressEnter="handelPressEnter"/>
          </a-form-item>
          <a-form-item label="关键词设置规则：">
            <div>
              <p>1、单个关键词输入后，按回车键保存（装修）+ 回车键 </p>
              <p>2、多个关键词请使用英文状态下“,”分割（贷款,利率,装修）+ 回车键</p>
              <p>3、组合关键词请使用“|”分割（贷款|利率|装修,教育|培训|幼儿）</p>
            </div>
            <div style="height: 200px;overflow: auto;border: 1px solid #e4e4e4;padding: 10px">
              <a-tag style="margin-bottom: 10px" @close="handleClose(item)" closable
                     v-for="(item, index) in formCreateUpdate.keywords" :key="item">{{ item }}
              </a-tag>
              <a-tag v-if="formCreateUpdate.keywords&&formCreateUpdate.keywords.length>0"
                     style="margin-bottom: 10px;margin-right: 10px;"
                     color="processing"
                     @click="copyKeywords"
                     key="copy">复制
              </a-tag>
            </div>
          </a-form-item>
        </a-form>
      </div>
      <template #footer>
        <a-button key="back" @click="handleCancel">取消</a-button>
        <a-button key="submit" type="primary" :loading="btnLoading" @click="handelSave">保存</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import {reactive, toRaw, ref} from 'vue';
import {PageModule} from '../common/pagination'
import _lineService from "@/api/open/LineQualityInspections";
import dictionary from '@/api/system/dictionary'
import {lastMonthConsume} from '@/api/dashboard/dashboard';
import amount from '@/api/open/amount/amount'
import {copy} from "@/utils/common";


export default {
  name: 'RuleKey',
  props: {
    voiceOption: {
      type: Array,
      default: () => []
    },
    isAdminUser: {
      type: String,
      default: () => ''
    },
    userOptions: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      ba: "",
      industryList: reactive([]),
      industryTreeList: [],
      industryValue: [],
      industryTreeProps: {
        checkStrictly: true,//Select any level of options
      },
      loading: false,
      tableData: [],
      formState: reactive(new FormModel()),
      formCreateUpdate: reactive(new CreateUpdateModule()),
      visible: false,
      rowId: '',
      pageInfo: new PageModule(),
      btnLoading: false,
      formRef: ref(),
      types: [
        {label: '违规关键词', abLabel: '违规', value: 1},
        {label: '线路行业关键词', abLabel: '行业', value: 0},
        {label: '报备话术关键词', abLabel: '话术', value: 2},
      ],
      rules: {
        type: [
          {required: true, type: 'number', message: '请选择类型', trigger: 'blur'}
        ],
        name: [
          {required: true, message: '请输入关键词组名称', trigger: 'blur'}
        ],
        industryName: [
          {required: true, message: '请输入关键词组名称', trigger: 'blur'}
        ],
        industryId: [
          {required: true, message: '请选择行业', trigger: 'blur'}
        ],
        // risk: [
        //   {required: true, message: '请选择风险等级', trigger: 'change'}
        // ],
        status: [
          {required: true, type: 'number', message: '请选择状态', trigger: 'blur'}
        ],
        keywords: [
          {required: true, message: '请输入关键词', trigger: 'blur'}
        ],
      }
    };
  },
  computed: {
    isIndustryType() {
      //线路行业关键词：当选择线路行业后，线路行业自动作为关键词组名称，且不可更改
      return this.formCreateUpdate?.type == 0;//1-违规关键词 0-线路行业关键词 2-报备话术关键词
    },
    isIrregularityType() {
      //报备话术关键词：服务商系统每通过一条话术报备，列表生成一条，只可以通过“编辑”进行配置关键词，状态暂为“禁用”不可更改，且不可以更换“关键词组类型”进行编辑
      return this.formCreateUpdate?.type == 1;//1-违规关键词 0-线路行业关键词 2-报备话术关键词
    },
    isScriptType() {
      //报备话术关键词：服务商系统每通过一条话术报备，列表生成一条，只可以通过“编辑”进行配置关键词，状态暂为“禁用”不可更改，且不可以更换“关键词组类型”进行编辑
      return this.formCreateUpdate?.type == 2;//1-违规关键词 0-线路行业关键词 2-报备话术关键词
    },
    isEdit() {
      return this.rowId && this.rowId.length > 0;
    },
    typeDesc() {
      return row => {
        let find = this.types.find(type => type.value == row?.type);
        return find?.abLabel || '';
      }
    }
  },
  watch: {
    // visible(newV, preV) {
    //   if (!newV) {
    //     //弹窗消失后重置弹窗内容
    //     this.formCreateUpdate = new CreateUpdateModule()
    //     ref(this.$refs.formRef).value.resetFields();
    //     // this.industryValue = '';
    //   }
    // },
  },
  created() {
    this.getList()
    this.getIndustry()
  },
  methods: {
    // 获取通信账户类型
    getIndustry() {

      // amount.industryidlist().then((res) => {
      //   this.industryList = res.data
      //   // console.log(this.industryList, '00000000000--------9')
      // })

      dictionary.industry().then((res) => {
        //     console.log(res.data, '99999999999999999999999999999999999========================================')
        let arr = []
        this.industryTreeList = this.getIndustryTree(res.data, arr)
        // this.industryList = this.getIndustryList(res.data)
      })
    },
    getIndustryList(e) {
      let res = [];
      e.forEach(first => {
        res.push({
          amountId: first.amountId,
          id: first.id,
          name: first.name,
          pid: first.pid,
        })
        if (first.children && first.children.length > 0) {
          first.children?.forEach(second => {
            res.push({
              amountId: second.amountId,
              id: second.id,
              name: second.name,
              pid: second.pid,
            })
            if (second.children && second.children.length > 0) {
              second.children?.forEach(third => {
                res.push({
                  amountId: third.amountId,
                  id: third.id,
                  name: third.name,
                  pid: third.pid,
                })
              })
            }
          })
        }
      })
      return JSON.parse(JSON.stringify(res));
    },
    getIndustryTree(data, returnData) {
      if (!data) return returnData;
      if (Array.isArray(data)) {
        data.forEach((v, i) => {
          if (v.children.length != 0) {
            returnData?.push({
              amountId: v.amountId,//线路
              label: v.name,
              value: v.id,
              children: [],
            })
          } else {
            returnData?.push({
              amountId: v.amountId,
              label: v.name,
              value: v.id,
            })
          }

          if (v.children) {
            this.getIndustryTree(v.children, returnData?.[i]?.children)
          }
        })
      }
      return returnData;
    },
    getIndustryFromTree(tree, prop, id) {
      for (let i = 0; i < tree.length; i++) {
        if (tree[i][prop] == id) {
          return tree[i];
        }
        if (tree[i].children && tree[i].children.length > 0) {
          let find = this.getIndustryFromTree(tree[i].children, prop, id);
          if (find) {
            return find;
          }
        }
      }
      return null;
    },
    typeChange(e) {
      // let type = e?.target?.value;
      // this.formCreateUpdate.name = '';
      // this.industryValue = '';
      // this.formCreateUpdate.risk = ''
      // this.formCreateUpdate.keywords = []
      // this.formCreateUpdate.status = 0
      // this.formCreateUpdate.type = 1 //1-违规关键词 0-线路行业关键词 2-报备话术关键词
      // this.formCreateUpdate.uid = ''
      // this.formCreateUpdate.keyword = ''
      // this.formCreateUpdate.industryId = ''
      // this.formCreateUpdate.unIndustryId = ''
    },
    industryChange(ids) {
      let industryId = (ids && ids.length > 0) ? ids[ids.length - 1] : '';
      let industry = this.getIndustryFromTree(this.industryTreeList, 'value', industryId);
      console.log('industryChange', ids, industryId, industry)
      this.formCreateUpdate.industryName = industry?.label;
    },
    async handelConfirm(row) {
      await _lineService._ruleService.deletes({id: row.id})
      this.$message.success('删除成功')
      this.getList()
    },
    copyKeywords() {
      if (this.formCreateUpdate.keywords) {
        let copyText = this.formCreateUpdate.keywords.join(',');
        copy(copyText);
      }
    },
    handleClose(keyword) {
      this.formCreateUpdate.delKeywords(keyword)
    },
    handelPressEnter() {
      this.formCreateUpdate.addKeywords()
    },
    handelSearch() {
      this.pageInfo.init()
      this.getList()
    },
    async getList() {
      const res = await _lineService._ruleService.getList(this.getParams())
      this.tableData = res.records.map(item => new TableModule(item))
      this.pageInfo.setTotal(res.total)
    },
    getParams() {
      if (this.isAdminUser === 'system') {
        if (!this.formState.uid) {
          this.formState.uid = 0;//系统用户默认给0
        }
      } else {
        this.formState.uid = '';//用户默认不给
      }
      const obj = toRaw(this.formState);
      return {
        ...this.pageInfo.getParamsJson(),
        ...obj.getParamsJson()
      }
    },
    async handelSave() {
      //这里如果编辑过，组件返回的this.industryValue是个集合，如果之前设置过，则是一个数字（究其原因是因为后端接口数据结构与组件返回结构不一致），所以注意处理
      let industryIds = this.industryValue || [];
      let industryId = (industryIds && industryIds.length > 0) ? industryIds[industryIds.length - 1] : this.industryValue;
      // console.log('handelSave', this.industryValue, industryIds, industryId, this.formCreateUpdate)
      // return;

      this.btnLoading = true
      try {
        //type 1-违规关键词 0-线路行业关键词 2-报备话术关键词
        if (this.formCreateUpdate.type == 0) {
          //只有线路行业关键词才需要行业id
          this.formCreateUpdate.industryId = industryId;
          // this.formCreateUpdate.unIndustryId = ;
        } else {
          this.formCreateUpdate.industryId = '';
          this.formCreateUpdate.unIndustryId = '';
        }
        const obj = toRaw(this.formCreateUpdate)
        await this.$refs.formRef.validate()
        if (this.rowId) {
          await _lineService._ruleService.update(obj.getUpdateObj(this.rowId))
        } else {
          await _lineService._ruleService.created(obj.getCreateObj())
        }
        this.rowId = ''
        this.$message.success('保存成功')
        this.formCreateUpdate = new CreateUpdateModule()
        ref(this.$refs.formRef).value.resetFields();
        this.getList()
        this.$nextTick(() => {
          this.visible = false
        })
      } catch (err) {
        if (err) {
          this.$message.error(err.message)
        }
      }
      this.btnLoading = false
    },
    handleSizeChange(val) {
      this.pageInfo.setPageSize(val)
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageInfo.setCurrentPage(val)
      this.getList()
    },
    handleCancel() {
      this.rowId = ''
      this.formCreateUpdate = new CreateUpdateModule()
      ref(this.$refs.formRef).value.resetFields();
      this.$nextTick(() => {
        this.visible = false
      })
    },
    addRuleKey() {
      // this.rowId = ''
      // this.formCreateUpdate = new CreateUpdateModule()
      // ref(this.$refs.formRef).value.resetFields();
      this.industryValue = '';
      // console.log('addRuleKey', this.industryValue)
      this.$nextTick(() => {
        this.visible = true
      })
    },
    handelEdit(row) {

      this.rowId = row.id
      this.industryValue = row.industryId
      // console.log('handelEdit', this.industryValue, row)
      this.formCreateUpdate.setObjItem(row)
      this.$nextTick(() => {
        this.visible = true
      })
      //  let a=  JSON.parse(  JSON.stringify(row))
      // this.ba=a.industryId

    },
    handelReset() {
      this.$refs.formRef.resetFields()
      this.handelSearch()
    }
  },

};

/*
  质检类型 name id 用户名称 nickName
  敏感词 keywords 级别 riskText 状态 status 创建/更新 createTime / updateTime
*/
class TableModule {
  constructor(item) {
    this.nickName = item.nickName
    this.terminalName = item.terminalName
    this.name = item.name
    this.id = item.id
    this.keywords = item.keywords
    this.template = item.template
    this.riskText = this.getRiskText(item.risk)
    this.industryName = item.industryName
    this.industryNameList = item.industryName ? Array.isArray(item.industryName) ? item.industryName : item.industryName.split('/') : [];
    this.risk = item.risk
    this.status = item.status
    this.statusText = this.getStatusText(item.status)
    this.type = item.type
    this.createTime = item.createTime
    this.updateTime = item.updateTime
    // this.createTime_updateTime = (item.createTime || '--') + '/' + (item.updateTime || '--')
    this.uid = item.uid
    this.industryId = item.industryId
    this.unIndustryId = item.unIndustryId


  }

  getRiskText(val) {
    const obj = {
      0: '无',
      1: '低',
      2: '中',
      3: '高'
    }
    return obj[val] ? obj[val] : ''
  }

  getStatusText(val) {
    return val ? '禁用' : '正常'
  }

  getTypeText(val) {
    return val ? '风险类' : '行业类'
  }
}

/*
  是否命中 risk 类型名称 name
  状态 status 敏感词 keywords
*/
class FormModel {
  constructor() {
    this.risk = '4'
    this.name = undefined
    this.status = ''
    this.type = ''
    this.keywords = ''
    this.uid = 0
  }

  getParamsJson() {
    return {
      risk: this.risk === '4' ? '' : this.risk,
      name: this.name,
      status: this.status,
      type: this.type,
      keywords: this.keywords,
      uid: this.uid
    }
  }
}

/**
 *
 * isDefault: ""
 keywords: "1221"
 name: "此人"
 pass: true
 pri: "11"
 risk: 0
 status: 0
 */
/**
 * createTime: "2021-07-23 14:04:33"
 id: "52"
 isDefault: 1
 isDefaultText: "默认"
 keywords: "洋河酒,影,票房,影片,分红,认购,刷单,客引流,拓客,日结,五8同城,线上操作工,工作简单易懂,线上操作员,找到工作了,求职简历,德诚药业,发财棋牌,随机码,"
 name: "违规"
 nickName: "系统"
 pass: true
 pri: "2121"
 risk: 0
 riskText: null
 status: 0
 statusText: "启用"
 uid: "0"
 updateTime: "2022-03-17 17:48:41"
 */
class CreateUpdateModule {
  constructor() {
    this.name = ''
    this.industryName = '';//界面分开，提交时判断类型，行业类型时将该字段赋值给name
    this.risk = ''
    this.keywords = []
    this.status = 0
    this.type = 1 //1-违规关键词 0-线路行业关键词 2-报备话术关键词
    this.uid = ''
    this.keyword = '';
    this.industryId = '';
    this.unIndustryId = '';
    this.nickName = '';//渠道名称（弹窗展示用）
    this.terminalName = '';//企业名称（弹窗展示用）
    this.template = '';//报备话术（弹窗展示用）
  }

  getCreateObj() {
    return {
      name: this.type == 0 ? this.industryName : this.name,
      risk: parseInt(this.risk),
      // keywords: this.keywords?.join(',') || '',
      keywords: (Array.isArray(this.keywords) ? this.keywords?.join(',') : this.keywords) || '',
      status: this.status,
      type: this.type,
      industryId: this.industryId,
      unIndustryId: this.unIndustryId

    }
  }

  getUpdateObj(id) {
    return {
      name: this.type == 0 ? this.industryName : this.name,
      risk: parseInt(this.risk),
      // keywords: this.keywords?.join(',') || '',
      keywords: (Array.isArray(this.keywords) ? this.keywords?.join(',') : this.keywords) || '',
      id: id,
      status: this.status,
      type: this.type,
      uid: this.uid,
      industryId: this.industryId,
      unIndustryId: this.unIndustryId
    }
  }

  setObjItem(item) {
    this.name = item.name
    this.industryName = item.name
    this.risk = '' + item.risk
    this.keywords = (Array.isArray(item.keywords) ? item.keywords : item.keywords?.split(',')) || [];//格式化为字符串
    this.status = item.status
    this.type = item.type
    this.uid = item.uid
    //  let a=  JSON.parse(  JSON.stringify(item))
    this.industryId = item.industryId
    this.unIndustryId = item.unIndustryId
    this.nickName = item.nickName//渠道名称（弹窗展示用）
    this.terminalName = item.terminalName//企业名称（弹窗展示用）
    this.template = item.template//报备话术（弹窗展示用）
    // console.log(item, 'yyyyyyyyyyyyyyyy---------------')


    // console.log( this.industryId,'8888888888888888888888')
    // this.unIndustryId=item.unIndustryId
  }

  addKeywords() {
    if (!this.keyword) {
      return;
    }
    if (!this.keywords) {
      this.keywords = [];
    }
    if (this.keyword.indexOf(',') !== -1) {
      let keywordsSplit = this.keyword
          .split(',')
          .map(item => item.trim())
          .filter(item => item && item.length > 0)
          .filter(item => !this.keywords.find(keyword => keyword == item));
      this.keywords = this.keywords.concat(keywordsSplit);
    } else {
      if (!!this.keyword.trim() && !this.keywords.find(item => item == this.keyword.trim())) {
        this.keywords.push(this.keyword.trim())
      }
    }
    this.keyword = ''
  }

  delKeywords(item) {
    let findIndex = this.keywords.findIndex(keyword => keyword == item);
    if (findIndex != -1) {
      this.keywords.splice(findIndex, 1)
    }
  }
}
</script>
<style lang="less" scoped>
.add-from-s /deep/ .ant-form-item-label > label {
  width: 120px;
}

.box-page /deep/ .el-table .cell {
  overflow: hidden;
  white-space: nowrap; //禁止换行
  text-overflow: ellipsis; //...
}
</style>