// vos-用户设置
<template>
  <div class="menu">
    <!-- 1.头部搜索查询 -->
    <el-row>
      <el-form
        size="small"
        label-width="100px"
        :model="searchFrom"
        ref="searchFrom"
      >
        <el-col :span="8">
          <el-form-item label="手机号">
            <el-input
              v-model="searchFrom.telA"
              placeholder="请输入需要查询的手机号"
              size="mini"
              clearable
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="时间选择">
            <el-date-picker
            value-format="YYYY-MM-DD HH:mm:ss"
              :default-time="defaultTime"
              size="small"
              v-model="valueTime"
              type="datetimerange"
              align="right"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-form>

      <el-col :span="4">
        <div class="title-buttom">
          <div class="ml-10">
            <el-button
              icon="el-icon-plus"
              class="button-el"
              size="medium"
              @click="(type = 'add'), (searchDialogVisible = true)"
              >添加
            </el-button>
          </div>
          <div class="ml-10">
            <el-button
              icon="el-icon-search"
              class="button-el"
              size="medium"
              @click="getDataList1(true)"
              >查询
            </el-button>
          </div>
          <div class="ml-10">
            <el-button
              class="button-el"
              size="medium"
              @click="(type = 'edit'), (searchDialogVisible = true)"
              >批量删除
            </el-button>
          </div>
          <div class="ml-10">
            <div
              class="font-refresh-out flex-nowrap-center-center"
              @click="searchReset"
            >
              <icon-font
                v-if="loading == false"
                type="icon-shuaxin"
                class="font-refresh"
              />
              <i v-else class="el-icon-loading loding-color"></i>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 2.主体列表 -->
    <el-row>
      <el-col :span="24">
        <el-table
          @selection-change="handleSelectionChange"
          class="eltable"
          v-loading="loading"
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }"
          row-key="id"
          size="mini"
          :row-style="{ height: '50px' }"
          :cell-style="{ padding: '0px' }"
          default-expand-all
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        >
          <el-table-column type="selection" width="55"></el-table-column>

          <el-table-column label="主叫号码">
            <template #default="scope">{{
              scope.row.telA == null ? "-" : scope.row.telA
            }}</template>
          </el-table-column>

          <el-table-column label="创建日期">
            <template #default="scope">{{
              scope.row.createTime == null || scope.row.createTime == ""
                ? "-"
                : scope.row.createTime
            }}</template>
          </el-table-column>
          <el-table-column label="操作">
            <template #default="scope">
              <el-tooltip content="删除" placement="top" effect="light">
                <el-button
                  type="danger"
                  plain
                  size="mini"
                  icon="el-icon-delete"
                  @click="handleDelete('del', scope.row)"
                  circle
                >
                </el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <!-- 3.分页器 -->
    <div class="pager">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="searchFrom.page"
        background
        :page-sizes="[10, 50, 100, 200]"
        :page-size="searchFrom.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 查询用户模态框 -->
    <el-dialog
      :title="type == 'add' ? '添加质检白名单' : '删除质检白名单'"
      v-model="searchDialogVisible"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      width="600px"
    >
      <!-- <el-input v-model="upform.number"></el-input> -->
      <el-input
        v-model="Addnumber"
        type="textarea"
        placeholder="请输入手机号(换行可多输入)"
        :autosize="{ minRows: 8, maxRows: 15 }"
      />

      <div class="flex-nowrap-flex-end">
        <el-button
          class="cancel"
          plain
          @click="searchDialogVisible = false"
          round
        >
          取消
        </el-button>
        <el-button class="confirmAdd" @click="handleSearch" round>
          确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import store from "@/store/index";
import {
  whitePage,
  addBatch,
  deleteBatch,
} from "@/api/open/voiceCheck/voiceUser";
import { defaultTime } from "@/utils/timeToChoose.js";
import { IconFont } from "@/utils/iconfont.js";
import userts from "@/api/web/user";
import { ElMessageBox } from "element-plus";
import { h } from "vue";
export default {
  components: {
    IconFont,
  },
  data() {
    return {
      type: null,
      allIds: null,
      shortcuts: [
        {
          text: "上周",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          },
        },
        {
          text: "上月",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          },
        },
        {
          text: "前三月",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          },
        },
      ],
      Addnumber: "",
      addDialogVisible: false, //新增用户模态框

      valueTime: [], //选中时间段
      userList: [], // 查询用户的下拉集合
      searchDialogVisible: false, //查询用户模态框
      //查询参数
      searchFrom: {
        telA: "",
        page: 1,
        pageSize: 10,
        createTimeBegin: "",
        createTimeEnd: "",
      },
      total: 0,
      editDialogVisible: false, //编辑用户模态框
      //编辑参数
      defaultTime: [
        new Date(2000, 1, 1, 0, 0, 0),
        new Date(2000, 2, 1, 23, 59, 59),
      ],
      tableData: [], //菜单表
      loading: true,
      rules: {
        telAPrefix: [
          {
            required: true,
            message: "请输入主叫前缀",
            trigger: "blur",
          },
        ],
        industry: [
          {
            required: true,
            message: "请输入行业",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    //    this.dfTime()
    this.getDataList(true);
  },
  created() {},
  methods: {
    getDataList1(){
this.searchFrom.page = 1
this.getDataList(true)
    },
    dfTime() {
      // this.valueTime = [
      //   new Date(Date.now() - 1000*60*60*24*7).toLocaleDateString('fr-ca'),
      //   new Date().toLocaleDateString('fr-CA')
      // ]
    },
    // 列表多选--操作
    handleSelectionChange(val) {
      let newIds = [];
      val.forEach((item) => {
        newIds.push(item.telA);
      });
      // this.multipleSelection = val
      this.allIds = newIds;
    },

    unique(arr) {
      const res = new Map();
      return arr.filter((a) => !res.has(a) && res.set(a, 1));
    },
    // 查询
    handleSearch() {
      let upArr = [];
      let arrLest = this.Addnumber.split(/[(\r\n)\r\n]+/);
      this.unique(arrLest).forEach((item, i) => {
        upArr.push(item.trim());
      });

      let url;
      if (this.type == "add") {
        url = addBatch;
      } else {
        url = deleteBatch;
      }

      url(upArr).then((res) => {
        if (res.code !== 200) {
          return this.$message.error(res.message);
        } else {
          this.$message.success("操作成功");
          this.getDataList();
          // this.getDataList(true)
          this.Addnumber = "";
          this.searchDialogVisible = false;
        }
      });
    },
    // 查询重置
    searchReset() {
      this.searchFrom = {
        page: 1,
        pageSize: 10,
        telAPrefix: "", //主叫前缀
        uid: "", //用户ID
        industry: "", // 行业
        createTimeBegin: "",
        createTimeEnd: "",
      };
      this.valueTime = [];
      // this.valueTime = defaultTime()
      this.getDataList(true);
    },
    // 查询用户的下拉
    getVoiceName() {
      userts.queryList({}).then((res) => {
        if (res.code !== 200) {
          return this.$message.error(res.message);
        } else {
          this.userList = res.data;
        }
      });
    },
    //删除用户
    handleDelete(type, row) {
      let ids = [];
      if (type != "batchdel") {
        ids = [row.telA];
      } else {
        ids = this.allIds;
      }

      ElMessageBox({
        title: "提示消息",
        message: h("p", null, [h("span", null, "请再次确认是否删除 ")]),
        showCancelButton: true,
        confirmButtonText: "确认",
        cancelButtonText: "取消",
      })
        .then(() => {
          deleteBatch(ids).then((res) => {
            if (res.code !== 200) {
              return this.$message.error(res.message);
            } else {
              this.$message.success("删除成功");
              this.getDataList(true);
            }
          });
        })
        .catch(() => {
          this.$message.info("已取消删除");
        });
    },
    //编辑用户
    handleEdit(row) {
      console.log("row :>> ", row);
      this.editFrom.telAPrefix = row.telAPrefix;
      this.editFrom.id = row.id;
      this.editFrom.uid = row.uid;
      this.editFrom.industry = row.industry;
      this.editDialogVisible = true;
    },
    //编辑用户---->取消
    handleEditCancle(name) {
      this.$refs[name].resetFields();
      this.editDialogVisible = false;
    },
    //编辑用户---->确认
    handleEditSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (!valid) {
          return;
        } else {
          vosSettingEdit({ ...this.editFrom }).then((res) => {
            if (res.code !== 200) {
              return this.$message.error(res.message);
            } else {
              this.$refs[name].resetFields();
              this.editDialogVisible = false;
              this.getDataList(true);
              this.$message.success("编辑成功");
            }
          });
        }
      });
    },
    // 获取数据列表
    getDataList(type) {
      this.loading = type;
      if (this.valueTime.length > 0) {
        this.searchFrom.createTimeBegin = this.valueTime[0];
        this.searchFrom.createTimeEnd = this.valueTime[1];
      } else {
        this.searchFrom.createTimeBegin = "";
        this.searchFrom.createTimeEnd = "";
      }
      whitePage({
        ...this.searchFrom,
      }).then((res) => {
        if (res.code !== 200) {
          return this.$message.success(res.message);
        } else {
          this.tableData = res.data.records;
          this.total = res.data.total;
          this.loading = false;
        }
      });
    },
    // 分页
    handleSizeChange(val) {
      this.searchFrom.pageSize = val;
      this.getDataList(true);
    },
    handleCurrentChange(val) {
      this.searchFrom.page = val;
      this.getDataList(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  height: 100%;
  overflow-y: overlay;
  padding-right: 1.125rem;
  .title-name {
    font-size: 0.9375rem;
    font-weight: 600;
    color: #303133;
  }
  .top-first {
    .search-box {
      width: 18.75rem;
    }
  }
}
.screening {
  margin-bottom: 0.9375rem;
}
.el-row {
  margin-bottom: 0.9375rem;
  &:last-child {
    margin-bottom: 0;
  }
}

.title-buttom {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}
.editor {
  color: #0170ed;
  background: #e3f0ff;
}
.center-body {
  margin: 0 1.875rem;
}
.deletetable {
  color: #ff845b;
  background: #ffe4db;
}
.deletetable-other {
  margin-right: 1.25rem;
}
.confirmAdd {
  background: #637dff;
  color: #fff;
}
.cancel {
  color: #637dff;
  border: #637dff solid 1px;
}
.screening-out {
  margin: 0 0.9375rem;
}
.out-icon:hover {
  background: #e3f0ff;
  color: #0170ed;
}
.pager {
  display: flex;
  justify-content: flex-end;
}
.pass {
  color: #67c23a;
}
.unpass {
  color: #f56c6c;
}
.priceColor {
  color: #409eff;
}
.talks {
  cursor: pointer;
}
.record {
  height: 5rem;
}
.dialogue {
  overflow-y: overlay;
  height: 25rem;
  padding: 0.425rem 1.5rem;
  background: #f5f5f5;
}
.recordSon {
  width: 100%;
}
.item-text {
  display: inline-block;
  padding: 0.375rem;
  border-radius: 0.375rem;
  color: #000;
}
.item-left {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.item-right {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}
.title-left {
  text-align: left;
}
.title-right {
  text-align: right;
}
.title-item {
  padding: 0.375rem;
}
.talkcolor {
  font-size: 1rem;
  color: #fff;
}
.Read {
  background: #abadbb;
}
</style>

<style lang="scss">
.menu .el-collapse-item__content {
  padding-bottom: 0 !important;
}
.eltable {
  overflow-y: overlay;
}
</style>
