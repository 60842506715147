<template>
  <div class="m3-width-80 m3-flex-col m3-justify-content-start">
    <a-form ref="editFormRef" layout="horizontal"
            :model="editForm" :rules="editFormRules"
            :label-col="labelCol" :wrapper-col="wrapperCol"
            class="m3-width-full m3-flex-1 m3-relative">
      <a-form-item label="系统分析">
        <span v-if="editForm.infractions">该主叫为本年第{{ editForm.infractions }}次违规</span>
        <span v-else>该主叫本年未出现违规</span>
      </a-form-item>
      <a-form-item label="违规来源" name="orderFrom">
        <a-radio-group v-model:value="editForm.orderFrom" :options="orderFromList"
                       button-style="solid"/>
      </a-form-item>
      <a-form-item v-if="editForm.orderFrom==1" label="运营商处罚说明" name="oprPenalties">
        <a-textarea v-model:value="editForm.oprPenalties"
                    :rows="4">
        </a-textarea>
      </a-form-item>
      <a-form-item v-if="isManually" label="用&emsp;&emsp;户" name="uid">
        <a-select :disabled="formDisabled" v-model:value="editForm.uid" 
                  showSearch
                  :filterOption="(input, option)=> { return option.label.indexOf(input) >= 0;}"
                 >
                  <a-select-option v-for="item in userList" :key="item.value" :disabled="item.status===1"  :value="item.value"
                    :label="item.label">
    <template #default>
      <div>
        <span>{{ item.label }}</span>
        <span style="color:red;margin-left: 20px;" v-if="item.status === 1">禁</span>
      </div>
    </template>
  </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item v-if="isManually" label="服&ensp;务&ensp;商" name="amountId">
        <a-select :disabled="formDisabled" v-model:value="editForm.amountId" :options="amountList" 
                  showSearch
                  :filterOption="(input, option)=> { return option.label.indexOf(input) >= 0;}">
        </a-select>
      </a-form-item>
      <a-form-item v-if="isManually" label="主&emsp;&emsp;叫" name="caller">
        <div class="m3-flex">
          <a-input :disabled="formDisabled" v-model:value="editForm.caller"/>
        </div>
      </a-form-item>
      <a-form-item v-if="isManually" label="被&emsp;&emsp;叫" name="callee">
        <div class="m3-flex">
          <a-input :disabled="formDisabled" v-model:value="editForm.callee"/>
        </div>
      </a-form-item>
      <a-form-item v-if="isManually" label="通话时间" name="callTime">
        <div class="m3-flex">
          <a-date-picker :disabled="formDisabled"
                         v-model:value="editForm.callTime"
                         show-time format="YYYY-MM-DD HH:mm:ss"
                         @change="callTimeChange"/>
        </div>
      </a-form-item>
      <a-form-item label="处罚建议" name="punishPropType">
        <div class="m3-flex">
          <a-select :disabled="formDisabled" v-model:value="editForm.punishPropType" :options="punishTypeList"
                    class="m3-flex-2"
                    @change="punishTypeChange"></a-select>
          <span class="m3-margin-horizontal-s">-</span>
          <a-select :disabled="formDisabled" v-model:value="editForm.punishPropVal"
                    :options="punishValList"
                    class="m3-flex-5"></a-select>
        </div>
      </a-form-item>
      <a-form-item label="违规说明" name="violDesc">
        <div class="m3-relative">
          <a-textarea :disabled="formDisabled" v-model:value="editForm.violDesc" :rows="4">
          </a-textarea>
          <a-button class="m3-absolute" :style="{bottom:'16px',right:'16px'}" size="small" type="link"
                    @click="copyText">复制
          </a-button>
        </div>
      </a-form-item>
    </a-form>

    <div class="m3-absolute m3-flex m3-justify-content-end m3-padding-s"
         :style="{bottom:'20px',right:'20px'}">
      <a-button type="primary" :loading="buttonLoading" @click="next">下一步</a-button>
      <a-button class="m3-margin-left-s" @click="cancel">取消</a-button>
    </div>
  </div>
</template>

<script>
import {computed, reactive, ref, toRefs, watch} from "vue";
import {
  MAX_FORBIDDEN_HOUR,
  orderFromDesc,
  PUNISH_AMOUNT_LIST,
  PUNISH_DURATION_LIST, PUNISH_TYPE_LIST
} from "@/views/open/workOrder/WorkOrder";
import AButton from "ant-design-vue/lib/button/button";
import {Form, message} from "ant-design-vue";
import EmptyUtil from "@/utils/common/EmptyUtil";
import {modalConfirm} from "@/utils/common/modalConfirm";
import {safePromise} from "@/utils/common/safePromise";
import _lineService from "@/api/open/LineQualityInspections";
import ObjectUtil from "@/utils/common/ObjectUtil";
import {copy} from "@/utils/common";
import {isMobile} from "@/utils/validate";
import moment from "moment";

export default {
  name: "StepAdd",
  emits: ['next'],
  components: {AButton},
  props: {
    record: {
      type: Object,
      default: {},
    },
  },
  setup(props, context) {
    let {record} = toRefs(props);

    let formDisabled = computed(() => !EmptyUtil.isEmpty(record.value?.id));
    let buttonLoading = ref(false);
    let editFormRef = ref();
    let editForm = reactive({
      uid: null,// "渠道用户ID",
      amountId: null,// "服务商ID 线路ID",
      callId: null,// "通话ID",
      caller: null,// "主叫",
      callee: null,// "被叫",
      callTime: null,// "通话时间",手动创建才需要传
      //以上是checkId为空（即手动创建）时需要的参数
      checkId: null,//质检记录ID
      infractions: null,//违规次数
      violDesc: null,//违规说明
      orderFrom: null,//违规来源 0-平台质检 1-运营商质检
      oprPenalties: null,//运营商处罚内容
      punishPropType: null,//建议处罚类型 0-提醒 1-保证金（金额） 2-罚款（金额） 3-关停主叫 4-提醒/保金证（金额） 5-禁用主叫/罚款（金额）
      punishPropVal: null,//建议处罚量 punish_prop_type为0，此值为null； punish_prop_type为1或2，此值为金额，单位元；punish_prop_type为3，此值为时长，单位小时；
    });

    //是否为手动创建
    let isManually = computed(() => EmptyUtil.isEmpty(record.value.checkId))
    let punishTypeChange = () => {
      editForm.punishPropVal = null;
      if (editForm.punishPropType == 0) {
        delete editFormRules.punishPropVal;
      } else {
        editFormRules.punishPropVal = [{required: true, type: 'number', message: '请选择处罚量', trigger: 'change',}];
      }
    }
    let callTimeChange = () => {
      if (!EmptyUtil.isEmpty(editForm.callTime)) {
        if (editForm.callTime?._isAMomentObject) {
          //如果是时间类，时间格式化一下
          editForm.callTime = editForm.callTime.format('YYYY-MM-DD HH:mm:ss')
        }
      }
    }

    let editFormRules = reactive({
      violDesc: [{required: true, message: '请输入违规说明', trigger: 'blur',}],
      orderFrom: [{required: true, type: 'number', message: '请选择违规来源', trigger: 'change',}],
      punishPropType: [{required: true, type: 'number', message: '请选择处罚类型', trigger: 'change',}],
      // punishPropVal: [{required: true, message: '请选择处罚量', trigger: 'change',}],
    })
    let resetForm = () => {
      editForm.uid = null;
      editForm.amountId = null;
      editForm.callId = null;
      editForm.caller = null;
      editForm.callee = null;
      editForm.callTime = null;
      editForm.checkId = null;
      editForm.infractions = null;
      editForm.violDesc = null;
      editForm.orderFrom = null;
      editForm.oprPenalties = null;
      editForm.punishPropType = null;
      editForm.punishPropVal = null;
    }
    //useForm 表单验证
    let useForm = Form.useForm;
    const {resetFields, validate, validateInfos} = useForm(editForm, editFormRules);

    let userList = ref([]);
     let getUserList = async () => {
      const res = await safePromise(_lineService._recordService.queryList())
      userList.value = res?.data?.map((item) => ({
        label: `${item.nickname ? item.nickname + ' -- ' : ''}${item.company ? item.company + ' -- ' : ''}(Id:${item.uid})`,
        value: item.uid,
        status: item.status,
        nickname: item.nickname,
      })) || [];
  
    }
    let amountList = ref([]);
    let getAmountList = async () => {
      const res = await safePromise(_lineService._recordService.getAmountList())
      amountList.value = res?.data?.map(item => {
        return {label: item.name, value: Number.parseInt(item.id)}
      }) || [];
    }

    let getInfractionCount = async () => {
      // 查询违规次数
      let res = await safePromise(_lineService._workOrderService.getInfractionCount({caller: editForm.caller}));
      if (res?.code == 200) {
        editForm.infractions = res.data;
      } else {
        editForm.infractions = null;
      }
    }
    watch(record, (newV, preV) => {
      if (!EmptyUtil.isEmpty(newV)) {
        resetForm();
        Object.assign(editForm, newV);
        console.log('StepAdd watch record', editForm)
      } else {
        resetForm();
      }
    }, {immediate: true})

    watch(() => editForm.caller, (newV, preV) => {
      //监听主叫号码，获取违规次数
      if (EmptyUtil.isEmpty(record.value.id) && !EmptyUtil.isEmpty(newV) && isMobile(newV)) {
        getInfractionCount();
      }
    }, {immediate: true})

    watch(isManually, (newV, preV) => {
      console.log('isManually', newV, preV)
      if (newV) {
        editFormRules.uid = [{required: true, type: 'number', message: '请选择用户', trigger: 'change',}]
        editFormRules.amountId = [{required: true, type: 'number', message: '请选择服务商', trigger: 'change',}]
        editFormRules.caller = [{required: true, message: '请输入主叫号码', trigger: 'blur',}]
        editFormRules.callee = [{required: true, message: '请输入被叫号码', trigger: 'blur',}]
        editFormRules.callTime = [{required: true, message: '请选择通话时间', trigger: 'change',}]
        if (EmptyUtil.isEmpty(userList.value)) {
          getUserList();
        }
        if (EmptyUtil.isEmpty(amountList.value)) {
          getAmountList();
        }
      } else {
        delete editFormRules.uid;
        delete editFormRules.amountId;
        delete editFormRules.caller;
        delete editFormRules.callee;
        delete editFormRules.callTime;
      }
    }, {immediate: true})

    let orderFromList = [
      {
        value: 0,
        label: '平台质检',
      },
      {
        value: 1,
        label: '运营商质检',
      },
    ]

    //处罚类型 0-提醒 1-保证金（金额） 2-罚款（金额） 3-关停主叫 4-提醒/保金证（金额） 5-禁用主叫/罚款（金额）
    let punishTypeList = PUNISH_TYPE_LIST;

    //处罚量 0-提醒 1-保证金（金额） 2-罚款（金额） 3-关停主叫 4-提醒/保金证（金额） 5-禁用主叫/罚款（金额）
    let punishValList = computed(() => {
      let res = [];
      if (editForm.punishPropType == 0) {
        res = []
      } else if (editForm.punishPropType == 1 || editForm.punishPropType == 2 || editForm.punishPropType == 4 || editForm.punishPropType == 5) {
        res = PUNISH_AMOUNT_LIST;
      } else if (editForm.punishPropType == 3) {
        res = PUNISH_DURATION_LIST;
      }
      return res;
    })

    let copyText = () => {
      if (!EmptyUtil.isEmpty(editForm.violDesc)) {
        copy(editForm.violDesc)
      } else {
        message.warning('请输入报备内容');
      }
    }
    let cancel = () => {
      context.emit('next', -1);
    }

    let next = async () => {
      let confirm = true;
      let nextStatus = 3;
      // 新需求：来源和运营商质检说明可以修改
      let hasChanged = !EmptyUtil.isEmpty(record.value?.id)
          && (editForm.orderFrom != record.value.orderFrom
              || editForm.oprPenalties != record.value.oprPenalties);

      if (hasChanged || !formDisabled.value) {
        let valid = await safePromise(editFormRef.value?.validate());
        if (editForm.amountId && EmptyUtil.isEmpty(editForm.amountName)) {
          //本地处理一下amountName（服务商名称）
          let findAmount = amountList.value?.find(item => item.value == editForm.amountId);
          editForm.amountName = findAmount?.label;
        }
        if (editForm.uid && EmptyUtil.isEmpty(editForm.nickname)) {
          //本地处理一下nickname（用户名称）
         
          let findUser = userList.value?.find(item => item.value == editForm.uid);
          //console.log(findUser,'---');
          editForm.nickname = findUser?.label;
        }

        if (editForm.punishPropType != 0 && EmptyUtil.isEmpty(editForm.punishPropVal)) {
          message.error('请选择处罚量');
          return;
        }
        console.log('next valid', valid, EmptyUtil.isEmpty(valid?.errorFields));
        if (EmptyUtil.isEmpty(valid?.errorFields)) {
          //在编辑阶段，需要弹窗提示
          confirm = await modalConfirm('确认提交？');
        } else {
          confirm = false;
        }

        if (confirm) {
          buttonLoading.value = true;
          let params = ObjectUtil.toParams(editForm);
          if (params.callTime?._isAMomentObject) {
            //如果是时间类，时间格式化一下
            params.callTime = params.callTime.format('YYYY-MM-DD HH:mm:ss')
          }
          // console.log('next',params);

          let res;

          if (hasChanged) {
            //调用修改接口
            res = await safePromise(_lineService._workOrderService.editWorkOrder(params));
          } else {
            if (!isManually.value) {
              res = await safePromise(_lineService._workOrderService.addWorkOrder(params));
            } else {
              res = await safePromise(_lineService._workOrderService.addWorkOrderManually(params));
            }
          }


          if (res?.code == 200) {
            if (!EmptyUtil.isEmpty(res.data)) {
              Object.assign(record.value, res.data);
            }
            record.value.id = res.data?.id;
            if (!hasChanged) {
              //只是修改的话，不要重新设置状态
              record.value.status = nextStatus;
            }

            record.value.uid = record.value.uid || editForm.uid;//渠道用户ID
            record.value.nickname = record.value.nickname || editForm.nickname;//用户名称
            record.value.amountId = record.value.amountId || editForm.amountId;//服务商ID
            record.value.amountName = record.value.amountName || editForm.amountName;//服务商名称
            record.value.callId = record.value.callId || editForm.callId;//通话ID
            record.value.caller = record.value.caller || editForm.caller;//主叫
            record.value.callee = record.value.callee || editForm.callee;//被叫
            record.value.callTime = record.value.callTime || editForm.callTime;//通话时间
            record.value.checkId = record.value.checkId || editForm.checkId;//质检记录ID
            record.value.infractions = record.value.infractions || editForm.infractions;//违规次数
            record.value.violDesc = record.value.violDesc || editForm.violDesc;//违规说明
            record.value.orderFrom = record.value.orderFrom || editForm.orderFrom;//违规来源
            record.value.oprPenalties = record.value.oprPenalties || editForm.oprPenalties;//运营商处罚内容
            record.value.punishPropType = record.value.punishPropType || editForm.punishPropType;//建议处罚类型 0-提醒 1-保证金 2-罚款 3-关停主叫
            record.value.punishPropVal = record.value.punishPropVal || editForm.punishPropVal;//建议处罚量 punish_prop_type为0，此值为null； punish_prop_type为1或2，此值为金额，单位元；punish_prop_type为3，此值为时长，单位小时；
            context.emit('next', nextStatus);//传递下一个status进行跳转
            resetForm();
          } else {
            message.error(res?.message || res?.msg || '提交失败');
          }
          buttonLoading.value = false;
        }
      } else {
        context.emit('next', nextStatus);//传递下一个status进行跳转
      }
    }

    return {
      labelCol: {span: 4},
      wrapperCol: {span: 14},
      isManually, formDisabled, editForm, editFormRules, editFormRef,
      punishTypeChange, callTimeChange,
      userList, amountList, orderFromList, punishTypeList, punishValList,
      buttonLoading, next, cancel, copyText,
    }
  },
  computed: {
    orderFromDesc() {
      //来源 0-平台质检 1-运营商质检
      return orderFromDesc(this.record?.orderFrom)
    },
  },
}
</script>

<style scoped>

</style>