<template>
  <a-drawer :visible="dialogVisible"
            title="工单详情" width="50%"
            @close="dialogClose">
    <div class="m3-flex-col m3-align-items-start">
      <a-steps class="m3-width-80 m3-margin-top-xxl" size="small" direction="horizontal" :current="currentStepIndex">
        <a-step v-for="item in steps" :key="item.title" :title="item.title"/>
      </a-steps>
      <div class="m3-width-full m3-margin-top-xxl  m3-border-radius-s m3-padding-s">
        <StepAdd v-if="!currentStatus" :record="record" @next="next"/>
        <StepReport v-if="currentStatus==3" :record="record" @next="next"/>
        <StepApproval v-if="currentStatus==6" :record="record" @next="next"/>
        <StepNotice v-if="currentStatus==9" :record="record" @next="next"/>
        <StepAppeal v-if="currentStatus==12||(!isAdmin&&currentStatus>=15)" :record="record" @next="next"
                    @show-line-record-dialog="showLineRecordDialog"/>
        <StepComplete v-if="(isAdmin&&currentStatus>=15)" :record="record"
                      @next="next"
                      @show-line-record-dialog="showLineRecordDialog"/>
      </div>
    </div>
  </a-drawer>
</template>

<script>
import {ASteps} from "ant-design-vue/lib/steps";
import {computed, ref, toRefs, watch} from "vue";
import StepAdd from "@/views/open/workOrder/step/StepAdd.vue";
import EmptyUtil from "@/utils/common/EmptyUtil";
import {useStore} from "vuex";
import StepReport from "@/views/open/workOrder/step/StepReport.vue";
import StepApproval from "@/views/open/workOrder/step/StepApproval.vue";
import StepNotice from "@/views/open/workOrder/step/StepNotice.vue";
import StepAppeal from "@/views/open/workOrder/step/StepAppeal.vue";
import StepComplete from "@/views/open/workOrder/step/StepComplete.vue";

export default {
  name: "RecordDialog",
  emits: ['update:dialogVisible', 'showLineRecordDialog'],
  components: {StepComplete, StepAppeal, StepNotice, StepApproval, StepReport, StepAdd, ASteps},
  props: {
    dialogVisible: {
      type: Boolean,
      default: () => false,
    },
    /**
     * 注意格式：参照WorkOrder.js
     */
    record: {
      type: Object,
      default: {},
    },
  },
  setup(props, context) {
    let {record} = toRefs(props);
    let store = useStore();
    let isAdmin = computed(() => store.getters?.userType == 'system');

    let currentStepIndex = ref(0);
    //   工单状态: 3-待报备 6-待审批 9-待通知 12-待申诉 15-申诉待审批 18-已完结
    let steps = [
      {
        title: '创建工单',
        status: 0,
      },
      {
        title: '报备运营商',
        status: 3,
      },
      {
        title: '主管审批',
        status: 6,
      },
      {
        title: '派发工单',
        status: 9,
      },
      {
        title: '客户申诉',
        status: 12,
      },
      {
        title: '工单完结',
        status: 15,
      },
      // {
      //   title: '已作废',
      //   status: 17,
      //   hide: true,
      // },
      // {
      //   title: '已完结',
      //   status: 18,
      //   hide: true,
      // },
    ];
    // let showSteps = computed(() => steps.filter(item => !item.hide));
    let currentStatus = computed(() => steps[currentStepIndex.value]?.status);
    let currentRecordStatus = computed(() => record.value?.status);

    watch(record, (newV, preV) => {
      // console.log('watch record', newV)
      if (!EmptyUtil.isEmpty(newV)) {
        let findIndex = steps.findIndex(step => step.status == newV.status);
        if (findIndex > -1) {
          currentStepIndex.value = findIndex;
        } else {
          currentStepIndex.value = newV.status >= 15 ? (steps.length - 1) : 0;
        }
      } else {
        currentStepIndex.value = 0;
      }
    }, {immediate: true})
    let dialogClose = () => {
      context.emit('update:dialogVisible', false);
    }
    let dialogConfirm = async () => {
      // dialogLoading.value = true;
      // let res = await safePromise(_lineService._recordService.getVoiceUserCheck({
      //   checkId: record.value.id,
      //   auditStatus: auditStatus.value
      // }));
      // dialogLoading.value = false;
      // if (res?.code == 200) {
      //   record.value.auditStatus = auditStatus.value;
      //   context.emit('confirm', {index: 1});
      //   setNextLoadingTimeout();
      //   resetAuditStatus();
      // }
    }

    let showLineRecordDialog = () => {
      context.emit('showLineRecordDialog');
    }

    let next = (status) => {
      if (status == 18) {
        status = 15;//已完结18也展示状态15的界面
      }
      //根据step传递的status进行相应的步骤跳转
      let nextIndex = steps.findIndex(step => step.status == status);
      console.log('RecordDialog next', status, nextIndex)
      if (nextIndex === -1) {
        currentStepIndex.value = 0;
        context.emit('update:dialogVisible', false);
      } else {
        currentStepIndex.value = nextIndex;
      }
    }
    return {
      isAdmin,
      currentStepIndex, steps, currentStatus,currentRecordStatus,
      showLineRecordDialog,
      dialogClose, dialogConfirm, next,
    }
  },
}
</script>

<style scoped>

</style>